import { Button } from "@material-ui/core";
import Skeleton from "components/Loaders/Skeleton";
import { ErrorToast } from "components/Toastbar";
import { images } from "config/images";
import { FormikErrors } from "formik";
import { Dispatch, SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import { ProfileThumbStyle } from "./ProfileThumb.styles";

interface PropsType {
  image_url: string;
  loading?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          image_url: string;
        }>
      >;
  setProfileEditMode: Dispatch<SetStateAction<boolean>>;
  profileEditMode: boolean;
}
const ProfileThumb = ({ image_url, setFieldValue, setProfileEditMode, loading, profileEditMode }: PropsType) => {
  const [selectedImage, setSelectedImage] = useState<string | ArrayBuffer | null>(null);
  const profileImage = profileEditMode ? selectedImage : image_url || images.placeholderProfile;

  return (
    <ProfileThumbStyle>
      <Skeleton loading={loading} animation="wave" height={280} width={280} style={{ transform: "inherit" }}>
        <img src={profileImage} onError={(e) => (e.currentTarget.src = images.placeholderProfile)} alt="Profile" />
      </Skeleton>
      <div className="edit-btn">
        <input
          accept="image/*"
          id="image_url-file"
          name="image_url"
          type="file"
          multiple={false}
          onChange={(event) => {
            if (event?.target?.files) {
              const file = event.target.files[0];
              if (file?.size > 2 * 1000 * 1000) {
                return toast.error(<ErrorToast>Image size can not exceed 2MB.</ErrorToast>);
              }

              if (file) {
                setFieldValue("image_url", file);
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = function () {
                  setProfileEditMode(true);
                  let image_url_result = reader.result;
                  setSelectedImage(image_url_result);
                };
              }
            }
          }}
        />
        <label htmlFor="image_url-file">
          <Button variant="contained" color="primary" component="span">
            Upload Photo
          </Button>
        </label>
      </div>
    </ProfileThumbStyle>
  );
};

export default ProfileThumb;
