import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupBy } from "@shipin/analytics-client/service";
import { ReportData } from "components/Charts/Charts.types";

export type Point = {
  name: string;
  id: string;
};

type State = {
  genericfleetPoint: ReportData[number] | null;
  genericvesselPoint: ReportData[number] | null;
  performanceAnalyticsFleet: (Point & { groupBy: GroupBy.FLEET | GroupBy.VESSEL_TYPE }) | null | "showAll";
  performanceAnalyticsVessel: Point | null;
};
const initialState: State = {
  genericfleetPoint: null,
  genericvesselPoint: null,
  performanceAnalyticsFleet: null,
  performanceAnalyticsVessel: null,
};
const genericReportselector = createSlice({
  name: "reportsSelector",
  initialState,
  reducers: {
    setGenericFleetPoint(state, action: PayloadAction<State["genericfleetPoint"]>) {
      state.genericfleetPoint = action.payload;
    },
    setGenericVesselPoint(state, action: PayloadAction<State["genericvesselPoint"]>) {
      state.genericvesselPoint = action.payload;
    },
    setPerformanceAnalyticsFleet(state, action: PayloadAction<State["performanceAnalyticsFleet"]>) {
      state.performanceAnalyticsFleet = action.payload;
    },
    setPerformanceAnalyticsVessel(state, action: PayloadAction<State["performanceAnalyticsVessel"]>) {
      state.performanceAnalyticsVessel = action.payload;
    },
  },
});

export const { setGenericFleetPoint, setGenericVesselPoint, setPerformanceAnalyticsFleet, setPerformanceAnalyticsVessel } =
  genericReportselector.actions;
export default genericReportselector.reducer;
