import L from "leaflet";
import { Box, Grid } from "@material-ui/core";
import { Marker, Circle, useMap } from "react-leaflet";
import { useQuery } from "@tanstack/react-query";
import { AisV1Service } from "@shipin/shipin-app-server-client";

import { useAppSelector, useVessels } from "hooks";
import { formatDate } from "utils";
import { useEngineRoomVesselFilter } from "queries";

import { Map } from "components/Map";
import { ReportsDateFilter } from "types/FilterBar.types";
import GenericChartCard from "components/ReportChartCard/GenericChartCard";
import { useEffect } from "react";

const endPointsIcon = new L.Icon({
  iconUrl: require(`assets/images/map-marker-icon.svg`).default,
  iconRetinaUrl: require(`assets/images/map-marker-icon.svg`).default,
  iconSize: [10, 10],
});
const endPointsIconship = new L.Icon({
  iconUrl: require("assets/VesselMarkers/global-db-default.svg").default,
  iconSize: [20, 20],
});

interface ReportsMapBaseProps extends ReportsDateFilter {
  vessel: string | undefined;
}
const ReportsMapBase = (props: ReportsMapBaseProps) => {
  const {
    vessel,
    dateRange: { start, end },
  } = props;
  const leafletMap = useMap();

  const { data } = useQuery({
    queryKey: ["vessel-path", vessel, start, end],
    queryFn: () => {
      if (!vessel) throw new Error("");

      return AisV1Service.postApiV1AisV1Query({
        start_dttm: formatDate(start),
        end_dttm: formatDate(end, "yyyy-MM-dd'T'23:59:59"),
        vessel_id: [vessel],
      });
    },
    staleTime: 0,
    gcTime: 0,
  });

  // instead of onSuccess
  useEffect(() => {
    const points = data?.results?.[0]?.points || [{ lat: null, lon: null }];
    const { lat, lon } = points?.[Math.floor(points?.length / 2)];
    if (lat != null && lon != null) leafletMap.fitBounds(points?.map((e) => [e.lat ?? 0, e.lon ?? 0]));
  }, [data?.results, leafletMap]);

  const points = data?.results?.[0]?.points;
  const lastPoint = points && points?.[points?.length - 1];

  if (!points?.length || !lastPoint?.lat || !lastPoint?.lon) return null;
  return (
    <>
      <Marker icon={endPointsIcon} position={[points[0].lat, points?.[0]?.lon ?? 0]} />
      {points.map((point, i) => (
        <Circle radius={2} key={i} color="#fff" center={[point.lat ?? 0, point.lon ?? 0]} />
      ))}
      <Marker
        key={lastPoint?.course}
        icon={endPointsIconship}
        position={[lastPoint?.lat, lastPoint?.lon]}
        rotationAngle={lastPoint?.course}
        rotationOrigin="center"
      />
    </>
  );
};

// We need to create ReportsMapBase because of the useMap hook
// Map component is context provider for useMap hook
const ReportsMap = ({ height, ...props }: ReportsMapBaseProps & { height?: string }) => (
  <Map height={height}>
    <ReportsMapBase {...props} />
  </Map>
);

const BridgeWatchMap = () => {
  const vessel = useAppSelector((state) => state.reportsFilterOld.vessel?.id);
  const dateRange = useAppSelector((state) => state.reportsFilterOld.dateRange);

  return <ReportsMap vessel={vessel} dateRange={dateRange} />;
};

const EngineRoomVesselMap = () => {
  const { vessel, start, end } = useEngineRoomVesselFilter();

  return <ReportsMap height="100%" vessel={vessel} dateRange={{ start, end }} />;
};

const MarpolVesselMap = () => {
  const vessel = useAppSelector((state) => state.marpolFilter.vessel?.id);
  const dateRange = useAppSelector((state) => state.marpolFilter.dateRange);

  return <ReportsMap vessel={vessel} dateRange={dateRange} />;
};

interface GenericVesselMapProps {
  vessel: string;
}
const GenericVesselMap = (props: GenericVesselMapProps) => {
  const { vessel } = props;
  const dateRange = useAppSelector((state) => state.genericReportsFilter.dateRange);
  const { data: vessels } = useVessels(vessel);
  const vesselName = !!vessels && !Array.isArray(vessels) ? vessels?.name : undefined;
  const title = `Map${vesselName ? `, For Vessel ${vesselName}` : ""}`.trim();

  // Grid and GenericChartCard wrappers are added to comply with the page level HTML
  return (
    <Grid className="item-card border-top border-bottom-none" item xs={12} style={{ height: "auto" }}>
      <GenericChartCard title={title}>
        <Box className="report-map" sx={{ width: "70%", margin: "0 auto" }}>
          <ReportsMap vessel={vessel} dateRange={dateRange} />
        </Box>
      </GenericChartCard>
    </Grid>
  );
};

const GenericReportsMap = () => {
  const vessel = useAppSelector((state) => state.genericReportselector.genericvesselPoint);
  if (!vessel || !vessel?.vessel_id) return null;

  return <GenericVesselMap vessel={vessel?.vessel_id} />;
};

export { BridgeWatchMap, EngineRoomVesselMap, MarpolVesselMap, GenericReportsMap };
