import { MapContainer } from "react-leaflet";
import styled, { css } from "styled-components";

export const MapContainerStyle = styled(MapContainer)<{ height?: string }>`
  display: flex;
  ${(props) =>
    props.height &&
    css`
      height: ${props.height} !important;
    `}
`;
