/**
 * Contains reusable validation schemas.
 */
import * as yup from "yup";

const name = (type = "Name") => yup.string().max(50, `${type} must not exceed 50 characters.`);
const email = () => yup.string().email("Please enter a valid email.").required("Email is required.");

const nameSchema = yup.object().shape({
  name: name()
    .matches(/^[0-9a-zA-Z( /)]+$/, "Please enter a valid Fleet name")
    .required("Please enter a Fleet Name"),
});

export const validation = { name, email, nameSchema };
