import React from "react";
import { XYChartData } from "components/Charts";
import InfoSummaryWidget from "components/InfoSummaryWidget/InfoSummaryWidget";
import { InfoSummaryBarColContainer, InfoSummaryBarRowContainer } from "./InfoSummaryBar.styles";
import { useReportsContext } from "components/Reports";

interface InfoSummaryBarProps {
  benchmarkRange: {
    min: number | undefined;
    max: number | undefined;
  };
  labels: XYChartData | undefined;
}
function InfoSummaryBar(props: InfoSummaryBarProps) {
  const {
    benchmarkRange: { min, max },
    labels,
  } = props;
  const { colors, fleetNames } = useReportsContext();

  const activities = [
    {
      count: `${min && Math.round(min)}% - ${max && Math.round(max)}`,
      bgColor: "#eb2c62",
      label: "Benchmark Range",
      hidden: min == null || max == null,
      className: "benchmark-range",
    },
    ...(labels ?? []).map((item) => ({ count: item.value, bgColor: colors[item.fleet_id], label: fleetNames[item.fleet_id] })),
  ];
  return (
    <InfoSummaryBarRowContainer>
      <InfoSummaryBarColContainer>
        <InfoSummaryWidget eventsData={activities} title="All Activities" />
      </InfoSummaryBarColContainer>
    </InfoSummaryBarRowContainer>
  );
}

export default InfoSummaryBar;
