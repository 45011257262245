import { useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  ActivitiesV1Service,
  ActivitiesTimelineV2Response,
  ReportsV1Service,
  SensorsV1Service,
  HeatmapCameraResponse,
  SensorsQueryResult,
} from "@shipin/shipin-app-server-client";

import { useAppSelector } from "hooks";
import { queryKeys } from "config";
import { EngineRoomReportsAmchartsObject, EngineRoomStatus, EngineRoomType, ReportsAmchartsObject } from "types/Reports.types";
import { color } from "config/variable.styles";
import { formatDate } from "utils";
import { differenceInHours } from "date-fns";
import { getOptions } from "components/Timelines/utils";
import { AppActivityFilter } from "@shipin/shipin-app-server-client";

function getEngineRoomAttributes(properties: { severity: EngineRoomStatus }): Pick<ReportsAmchartsObject, "color"> {
  const { severity } = properties;
  let result: Pick<ReportsAmchartsObject, "color"> = {
    color: "",
  };
  if (severity === "Routine") {
    result.color = color.aquaMarine;
  } else if (severity === "Alert") {
    result.color = color.coral;
  } else if (severity === "Attention") {
    result.color = color.paleOrange;
  } else {
    result.color = color.lightGray;
  }
  return result;
}

function convertEngineRoomDataForChart(data: ActivitiesTimelineV2Response, type: EngineRoomType | string) {
  const result: EngineRoomReportsAmchartsObject[] = [];
  data.timeline.forEach((data) => {
    const { severity, from_dttm, to_dttm, counts_by_severity } = data;
    result.push({
      amc_fromDate: new Date(from_dttm),
      amc_toDate: new Date(to_dttm),
      type: type,
      ...getEngineRoomAttributes({ severity }),
      data: counts_by_severity,
    });
  });
  return result;
}

export const select = (name: EngineRoomType | string) => (data: ActivitiesTimelineV2Response) => convertEngineRoomDataForChart(data, name);

function useEngineRoomVesselFilter() {
  const dateRange = useAppSelector((state) => state.engineRoomFilter.dateRange);
  const vessel = useAppSelector((state) => state.engineRoomFilter.vessel?.id);

  return {
    ...dateRange,
    vessel,
    formattedDate: { start_dttm: formatDate(dateRange.start), end_dttm: formatDate(dateRange.end, "yyyy-MM-dd'T'23:59:59") },
  };
}

function useEngineRoomVesselTimelineQuery(name: EngineRoomType) {
  const {
    vessel,
    formattedDate: { start_dttm, end_dttm },
  } = useEngineRoomVesselFilter();

  const diference = differenceInHours(new Date(end_dttm), new Date(start_dttm));
  const { resolution } = getOptions(diference);

  const fallback: EngineRoomReportsAmchartsObject = {
    amc_fromDate: new Date(start_dttm),
    amc_toDate: new Date(end_dttm),
    type: name,
    color: "#EFEFEF",
    data: {
      Alert: 0,
      Attention: 0,
      Routine: 0,
    },
  };

  const query = useQuery({
    ...queryKeys.manualRefresh.engineRoomVesselCompartment({ name, start_dttm, end_dttm, vessel }),
    queryFn: () => {
      if (!vessel) throw new Error("");

      return ActivitiesV1Service.postApiV1ActivitiesV1ActivitiesTimelineV4({
        filters: { compartment_name: [name], vessel_id: [vessel], relative_time: AppActivityFilter.relative_time.NONE, blacklist: false },
        timeline_range: { min: start_dttm, max: end_dttm },
        resolution,
        grouping: ["SEVERITY"],
      });
    },
    enabled: !!vessel,
    select: select(name),
  });

  return {
    ...query,
    isFulfilled: query.isSuccess || query.isError,
    data: !query.data || !query.data.length ? [fallback] : query.data,
  };
}

function useEngineRoomVesselActivities() {
  const {
    vessel,
    dateRange: { start, end },
    compare_to,
  } = useAppSelector((state) => state.engineRoomFilter);

  return useQuery({
    ...queryKeys.manualRefresh.engineRoomVesselActivities(start, end, vessel?.id, compare_to),
    queryFn: async () => {
      if (!vessel?.id) return Promise.reject("err");

      return ReportsV1Service.postApiV1ReportsV1EngineRoomV1VesselActivities({
        start_dttm: formatDate(start),
        end_dttm: formatDate(end, "yyyy-MM-dd'T'23:59:59"),
        vessel_id: vessel?.id,
        fleet_id: compare_to === "INDUSTRY_BENCHMARK" ? undefined : compare_to,
      });
    },
    enabled: !!vessel?.id,
    staleTime: 0,
    gcTime: 0,
    select: (d) => d.activities,
  });
}

const compartments: Record<string, string> = {
  ECR: "Engine Control Room",
  Generator: "Generator Space",
  "Main Engine": "Main Engine Space",
  SGR: "Steering Gear Area",
  Purifiers: "Purifier Area",
};

function useEngineRoomVesselHeatmap() {
  const { start, end, vessel } = useEngineRoomVesselFilter();

  const {
    data: camerasMap,
    isFetching: isSensorsLoading,
    isError: isSensorError,
  } = useQuery({
    ...queryKeys.manualRefresh.sensorNames(vessel),
    queryFn: () => {
      if (!vessel) throw new Error("");

      return SensorsV1Service.postApiV1SensorsV1SensorQuery({
        filters: {
          live_view_status: ["ACTIVE", "AVAILABLE", "LOST_CONNECTIONS", "UNAVAILABLE"],
          compartment_name: ["ECR", "Main Engine", "SGR", "Generator", "OWS", "Purifiers"],
          area_name: ["Engine Room"],
          vessel_id: [vessel],
        },
      });
    },
    enabled: !!vessel,
    select: useCallback((data: SensorsQueryResult) => {
      const cameraNames: Record<string, string> = {};
      data?.sensors_query_result?.forEach((e) => {
        cameraNames[e.compartment_name] = e.id;
      });
      return { cameraNames, cameraIds: data?.sensors_query_result?.map((e) => e.id) };
    }, []),
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const {
    data: heatMaps,
    isFetching: isHeatmapLoading,
    isError: isHeatmapError,
  } = useQuery({
    ...queryKeys.manualRefresh.engineRoomVesselHeatMap(start, end, camerasMap?.cameraIds),
    queryFn: async () => {
      if (!camerasMap?.cameraIds) return Promise.reject("");

      const response = await ReportsV1Service.postApiV1ReportsV1CameraHeatmap({
        start_date: formatDate(start, "yyyy-MM-dd"),
        end_date: formatDate(end, "yyyy-MM-dd"),
        camera_id: camerasMap?.cameraIds,
      });
      return response;
    },
    enabled: !!camerasMap?.cameraIds,
    select: useCallback((data: HeatmapCameraResponse) => {
      const heatmapNames: Record<string, string> = {};
      data?.heatmaps?.forEach((e) => {
        heatmapNames[e.camera_id] = e.url;
      });
      return heatmapNames;
    }, []),
  });

  const heatmaps = useMemo(
    () =>
      Object.keys(compartments).map((c) => {
        const id = camerasMap?.cameraNames?.[c] ?? "";
        return {
          name: compartments[c],
          url: heatMaps?.[id],
        };
      }),
    [heatMaps, camerasMap?.cameraNames]
  );

  return { heatmaps, isLoading: isSensorsLoading || isHeatmapLoading, isError: isSensorError || isHeatmapError };
}

export { useEngineRoomVesselTimelineQuery, useEngineRoomVesselFilter, useEngineRoomVesselActivities, useEngineRoomVesselHeatmap };
