import { CSSProperties } from "react";
import styled, { css } from "styled-components";
import { color, rem } from "../../config/variable.styles";

interface SeverityPropType {
  bordercolor: CSSProperties["color"];
  noborder?: boolean;
  backgroundColor?: string;
}

const popoverBackgroundStyle = (props: SeverityPropType) => {
  if (props.noborder) {
    return css`
      background-color: white;
    `;
  }
  return css`
    background-color: ${props.bordercolor};
  `;
};

const popoverBorderLeftStyle = (props: SeverityPropType) => {
  if (props.noborder) {
    return null;
  }
  return css`
    border-left: ${rem(6)} solid ${props.bordercolor};
  `;
};

const popoverBorderRightStyle = (props: SeverityPropType) => {
  if (props.noborder) {
    return css`
      border-right: ${rem(6)} solid white;
    `;
  }
  return css`
    border-right: ${rem(6)} solid ${props.bordercolor};
  `;
};

export const PopoverArrow = styled.div<SeverityPropType>`
  overflow: hidden;
  height: ${rem(22)};
  width: ${rem(33)};
  &::after {
    content: "";
    position: absolute;
    width: ${rem(6)};
    height: ${rem(26)};
    ${(props) => popoverBackgroundStyle(props)}
    left: 0;
    transform: skew(0deg, -37deg);
    top: ${rem(-6)};
  }
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: ${rem(22)} solid ${color.White};
    border-right: ${rem(33)} solid transparent;
    left: 0;
  }
`;

export const PopoverContainer = styled.div<SeverityPropType>`
  background: ${(props) => props.backgroundColor};
  ${(props) => popoverBorderLeftStyle(props)}
  z-index: 1600;
  &.timeline-popover {
    border-radius: ${rem(4)};
    margin-left: ${rem(-28)};
    filter: drop-shadow(rgba(0, 0, 0, 0.26) 0px 0.125rem 0.25rem);
    &.center {
      text-align: center;
    }
    &.rounded {
      border-radius: ${rem(8)};
    }
    [data-popper-placement="top-end"]& {
      margin-left: ${rem(28)};
      inset: auto ${rem(-27)} ${rem(-10)} auto !important;
    }
    [data-popper-placement="top-start"]& {
      inset: auto auto ${rem(-10)} 0 !important;
    }
    [data-popper-placement="bottom-end"]& {
      margin-top: ${rem(-10)};
      margin-left: ${rem(0)};
      margin-right: ${rem(-20)};
      &::after {
        bottom: inherit;
        top: ${rem(-8)};
        right: ${rem(20)};
        left: inherit;
        background: ${color.SapphireDark};
      }
    }
    [data-popper-placement="bottom-start"]& {
      margin-top: ${rem(-10)};
      &::after {
        bottom: inherit;
        top: ${rem(-8)};
        background: ${color.SapphireDark};
      }
    }
    &.arrow-left {
      &::after {
        left: ${rem(23)};
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: ${rem(15)};
      height: ${rem(15)};
      bottom: ${rem(-7)};
      left: ${rem(20)};
      background: ${color.White};
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: ${(props) => props.backgroundColor};
      z-index: -1;
      [data-popper-placement="top-end"]& {
        left: auto;
        right: ${rem(20)};
      }
    }
  }
  &.reports-popover {
    border: none !important;
    &[data-popper-placement^="top-end"] > div {
      position: relative;
      margin-right: -5%;
      top: ${rem(10)};
    }
    &[data-popper-placement^="bottom-end"] > div {
      position: relative;
      margin-right: -5%;
      top: ${rem(-8)};
    }
    &[data-popper-placement^="top-start"] > div {
      position: relative;
      margin-left: -5%;
      top: ${rem(10)};
    }
    &[data-popper-placement^="bottom-start"] > div {
      position: relative;
      margin-left: -5%;
      top: ${rem(-8)};
    }
    &.compliant-watch-popover {
      &[data-popper-placement^="top-start"] > div {
        margin-left: -3%;
      }
    }
  }
  &[data-popper-reference-hidden^="true"] {
    visibility: hidden;
    pointer-events: none;
  }
  &[data-popper-placement^="right"] {
    ${PopoverArrow} {
      left: ${rem(0)};
    }
  }

  &[data-popper-placement^="left"] {
    ${PopoverArrow} {
      right: ${rem(0)};
    }
  }

  &[data-popper-placement^="top"] {
    ${PopoverArrow} {
      bottom: ${rem(-20)};
      margin-left: ${rem(-6)};
    }
  }
  &[data-popper-placement^="top-start"] {
    ${PopoverArrow} {
      bottom: ${rem(-21)};
      margin-left: ${rem(-6)};
    }
  }
  &[data-popper-placement^="top-end"] {
    border-left: 0;
    ${(props) => popoverBorderRightStyle(props)}
    ${PopoverArrow} {
      bottom: ${rem(-21)};
      margin-left: ${rem(6)};
      &::after {
        transform: skew(0deg, 37deg);
        left: ${rem(27)};
      }
      &::before {
        transform: scaleX(-1);
      }
    }
  }
  &[data-popper-placement^="bottom"] {
    ${PopoverArrow} {
      top: ${rem(0)};
    }
  }
`;
