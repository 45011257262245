import { useState, ReactNode, CSSProperties } from "react";
import { usePopper } from "react-popper";
import { Placement, VirtualElement } from "@popperjs/core";
import { PopoverContainer, PopoverArrow } from "./PopperPopover.styles";

interface PropType {
  referenceElement: Element | VirtualElement | null;
  children: ReactNode;
  placement?: Placement;
  borderColor?: CSSProperties["color"];
  noborder?: boolean;
  className?: string;
  backgroundColor?: string;
  noArrow?: boolean;
  offset?: number;
}
const PopperPopover = ({
  referenceElement,
  backgroundColor = "#fff",
  className,
  noborder,
  placement = "auto",
  children,
  borderColor = "#fff",
  noArrow = false,
  // This is added after popover is used everywhere, do 24 is the default value to support the existing implementation
  offset = 24,
}: PropType) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      { name: "offset", options: { offset: [0, offset] } },
    ],
    placement,
  });

  return (
    <PopoverContainer
      className={!!className ? className : ""}
      noborder={noborder}
      ref={setPopperElement}
      style={styles.popper}
      bordercolor={borderColor}
      backgroundColor={backgroundColor}
      {...attributes.popper}
    >
      {children}
      {!noArrow && <PopoverArrow noborder={noborder} ref={setArrowElement} style={styles.arrow} bordercolor={borderColor} />}
    </PopoverContainer>
  );
};

export default PopperPopover;
