import { useSyncExternalStore } from "react";

function subscribe(cb: () => void) {
  window.addEventListener("scroll", cb);
  return () => {
    window.removeEventListener("scroll", cb);
  };
}

/**
 * Returns true if the page scroll is exceeding scrollOffset
 * @param scrollOffset Scroll offset
 */
function useStickyHeader(scrollOffset: number) {
  return useSyncExternalStore(subscribe, () => window.scrollY >= scrollOffset);
}

export { useStickyHeader };
