const colors = {
  primary: {
    50: "#F1F3FF",
    100: "#E5E7FF",
    200: "#CED3FF",
    300: "#A7AEFF",
    400: "#767AFF",
    500: "#443FFF",
    600: "#2B18FF",
    700: "#1A07FA",
    800: "#1505D2",
    900: "#1306AC",
    950: "#040066",
  },
  red: {
    50: "#FEF2F2",
    100: "#FEE3E2",
    200: "#FECBCA",
    300: "#FBA8A6",
    400: "#F77572",
    500: "#F05F5C",
    600: "#DB2B27",
    700: "#B8201D",
    800: "#981F1C",
    900: "#7E201E",
    950: "#450B0A",
  },
  orange: {
    300: "#FFCA70",
    400: "#FFA737",
  },
  green: {
    500: "#0BBE90",
  },
  gray: {
    50: "#F7F7F7",
    100: "#EDEDED",
    200: "#DFDFDF",
    300: "#C8C8C8",
    400: "#B4B4B4",
    500: "#999999",
    600: "#888888",
    700: "#7B7B7B",
    800: "#676767",
    900: "#545454",
    950: "#363636",
  },
  blue: {
    200: "#A2F1FF",
    500: "#00B4DE",
    700: "#0A7094",
    950: "#063146",
  },
  secondary: {
    50: "#F1F8FA",
    100: "#DAECF0",
    200: "#BDDCE4",
    300: "#90C3D0",
    400: "#5BA0B5",
    500: "#40849A",
    600: "#386D82",
    700: "#32596C",
    800: "#304C5A",
    900: "#2C414D",
    950: "#192933",
  },
  background: "#F1F6FC",
};

export { colors };
