import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { previousMonth, previousSixMonths } from "components/Filters/DateRangePicker";
import { endOfMonth, startOfMonth } from "date-fns";

const initialState: {
  topic: string;
  kpi: {
    value: string;
    id: string;
  } | null;
  dateRange: {
    start: string;
    end: string;
  };
  fleet: string[] | "All";
  vessel: string[] | "All";
  benchMarkTypes: string[] | "All";
} = {
  topic: "",
  kpi: null,
  dateRange: {
    start: startOfMonth(previousSixMonths).toString(),
    end: endOfMonth(previousMonth).toString(),
  },
  fleet: "All",
  vessel: "All",
  benchMarkTypes: "All",
};

const genericReportsFilter = createSlice({
  name: "genericReportsFilter",
  initialState,
  reducers: {
    setGenericReportsDateRange(state, action: PayloadAction<typeof initialState.dateRange>) {
      state.dateRange = action.payload;
    },
    setGenericReportTopic(state, action: PayloadAction<string>) {
      state.topic = action.payload;
    },
    setGenericReportKpi(state, action: PayloadAction<typeof initialState.kpi>) {
      state.kpi = action.payload;
    },
    setGenericReportFleets(state, action: PayloadAction<typeof initialState.fleet>) {
      state.fleet = action.payload;
    },
    setGenericReportVessels(state, action: PayloadAction<typeof initialState.vessel>) {
      state.vessel = action.payload;
    },
    setGenericReportBenchMarks(state, action: PayloadAction<typeof initialState.benchMarkTypes>) {
      state.benchMarkTypes = action.payload;
    },
    removeFromGenericFleetandVessel(state, action: PayloadAction<{ fleet: string[]; vessel: string[] }>) {
      if (state.vessel !== "All" && !!action.payload.vessel.length) {
        state.vessel = state.vessel.filter((vessel) => !action.payload.vessel.includes(vessel));
      }
      if (state.fleet !== "All" && !!action.payload.fleet.length) {
        state.fleet = state.fleet.filter((fleet) => !action.payload.fleet.includes(fleet));
      }
    },
  },
});

export const {
  setGenericReportsDateRange,
  setGenericReportKpi,
  setGenericReportTopic,
  setGenericReportFleets,
  setGenericReportVessels,
  setGenericReportBenchMarks,
  removeFromGenericFleetandVessel,
} = genericReportsFilter.actions;
export default genericReportsFilter.reducer;
