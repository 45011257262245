import { useEffect } from "react";
import { Grid } from "@material-ui/core";
import axios from "config/api";
import { reports } from "constants/api/reports";
import { useAppSelector } from "hooks/reduxHooks";
import useAsync from "hooks/useAsync";
import { HeatmapRequest, HeatmapResponse } from "types/Reports.types";
import Skeleton from "components/Loaders/Skeleton";
import { formatDate } from "utils";
import { Error } from "../../Charts/Error";
const Heatmap = () => {
  const {
    vessel,
    dateRange: { start, end },
  } = useAppSelector((state) => state.reportsFilterOld);
  const refresh = useAppSelector((state) => state.refreshTimer.manualRefresh);
  const { fetch, data, loading, error } = useAsync<HeatmapRequest, HeatmapResponse>({
    fetcher: (args) => axios.post(reports.heatmap, args),
  });
  const skeletonProps = {
    style: {
      transform: "inherit",
      borderRadius: 0,
      height: "220px",
      width: "100%",
    },
    loading,
  };

  useEffect(() => {
    if (!vessel?.id) return;
    fetch({
      start_dttm: formatDate(start),
      end_dttm: formatDate(end, "yyyy-MM-dd'T'23:59:59"),
      vessel_id: vessel.id,
    });
  }, [fetch, start, end, vessel, refresh]);

  const [first, second] = data && data.heatmaps ? data.heatmaps : [];
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Skeleton {...skeletonProps}>
            {first && first?.url ? (
              <img src={first.url} alt={first.camera_id} />
            ) : (
              <Error heatMap variant={error ? "error" : "nodata"} height={220} size="xs" text="heatmap" />
            )}
          </Skeleton>
        </Grid>

        <Grid item xs={12}>
          <Skeleton {...skeletonProps}>
            {second && second?.url ? (
              <img src={second.url} alt={second.camera_id} />
            ) : (
              <Error heatMap variant={error ? "error" : "nodata"} height={220} size="xs" text="heatmap" />
            )}
          </Skeleton>
        </Grid>
      </Grid>
    </>
  );
};

export { Heatmap };
