import React, { CSSProperties, ReactNode, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";

import InfoOutline from "components/Icon/Info-outline";
import Skeleton from "components/Loaders/Skeleton";
import { ChartCardStyles } from "./ChartCard.styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverWrap: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
);
interface ChartCardProps {
  title?: string;
  subTitle?: string;
  rightBorder?: boolean;
  children?: ReactNode;
  loading?: boolean;
  tooltipContent?: ReactNode;
  /** isAlert is used to indicate use display flex in chart container. */
  isAlert?: boolean;
  reverseHeading?: boolean;
  topRadiusNone?: boolean;
  bottomRadiusNone?: boolean;
  height?: number | string;
  chartCardStyle?: CSSProperties;
  className?: string;
}
const ChartCard = (props: ChartCardProps) => {
  const {
    children,
    title,
    subTitle,
    rightBorder,
    topRadiusNone,
    bottomRadiusNone,
    loading,
    tooltipContent,
    reverseHeading = false,
    isAlert = false,
    height,
    chartCardStyle,
    className,
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ChartCardStyles
      height={height}
      className={`
    ${rightBorder ? "rightGrayBorder" : ""} 
    ${topRadiusNone ? "topRadiusNone" : ""} 
    ${bottomRadiusNone ? "bottomRadiusNone" : ""}`.trim()}
    >
      <div className={`heading ${className ?? ""}`}>
        <div className={`headingTitle ${reverseHeading ? "orderingHeading" : ""}`}>
          {subTitle && (
            <Skeleton loading={loading} animation="wave" height={20} width="100px">
              <h4>{subTitle} </h4>
            </Skeleton>
          )}
          <Skeleton loading={loading} animation="wave" height={20} width="100px">
            <h2>
              {title}
              <div
                aria-owns={open ? "heading-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <InfoOutline />
              </div>
            </h2>
            {tooltipContent && (
              <Popover
                id="heading-over-popover"
                className={classes.popoverWrap}
                classes={{
                  paper: classes.paper,
                }}
                // open={true}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                {tooltipContent}
              </Popover>
            )}
          </Skeleton>
        </div>
        <div className="reportsHeadingSummary"></div>
      </div>
      <div style={chartCardStyle} className={isAlert ? "ChartCardContent AlertChartContent" : "ChartCardContent"}>
        {children}
      </div>
    </ChartCardStyles>
  );
};
export default ChartCard;
