const ErrorIcon = () => {
  return (
    <span className="icon icon-error">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
        <defs>
          <filter id="psqn24n72a" colorInterpolationFilters="auto">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.988814 0 0 0 0 0.727868 0 0 0 0 0.335278 0 0 0 1.000000 0" />
          </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g filter="url(#psqn24n72a)" transform="translate(-1374.000000, -214.000000)">
            <g>
              <g fill="#000" fillRule="nonzero">
                <path
                  d="M14.97 
                  16.415l-.3-4.488c-.06-.903-.368-2.363.322-3.097.525-.564 
                  1.768-.661 2.145.121.32.92.392 1.907.206 2.863l-.402 4.62c-.018.435-.113.864-.281 
                  1.265-.136.265-.407.432-.704.435-.297.003-.571-.159-.712-.42-.159-.416-.251-.854-.274-1.299zm1.04 
                  6.168c-.74-.005-1.348-.582-1.39-1.32-.043-.738.496-1.381 1.23-1.47.516-.063 1.026.167 1.321.597.295.43.327.987.083 
                  1.448-.244.46-.723.747-1.245.745z"
                  transform="translate(1374.000000, 214.000000) translate(0.000000, 2.666667)"
                />
                <path
                  d="M28.636 27H3.365c-1.208-.019-2.317-.674-2.916-1.723-.599-1.05-.599-2.337 0-3.387l12.65-20.278C13.724.608 
                  14.824-.002 16.007 0c1.182.002 2.28.616 2.901 1.622l12.63 20.245c.61 1.049.616 2.342.02 3.397-.598 1.056-1.71 1.717-2.922 
                  1.736zM15.028 2.784L2.38 23.062c-.19.35-.182.775.02 1.118.2.344.567.557.966.562h25.271c.403-.006.773-.224.972-.574.2-.35.2-.78 
                  0-1.13 0 
                  0-12.63-20.247-12.636-20.254-.21-.333-.578-.534-.972-.534s-.76.201-.972.534z"
                  transform="translate(1374.000000, 214.000000) translate(0.000000, 2.666667)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

export default ErrorIcon;
