import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { BenchmarkType } from "@shipin/analytics-client/service";
import { AppActivityFilter } from "@shipin/shipin-app-server-client";
import { Severity } from "types";
import { FleetReportFilter } from "types/FilterBar.types";
import { EngineRoomType } from "types/Reports.types";

/**
 * Return current snapshot of filters to Query Key.
 */
const filtersQuery = (filters: AppActivityFilter) => [{ filters }] as const;
const getDetailsById = (id: string | undefined) => [{ id }] as const;

export const queryKeys = createQueryKeyStore({
  sensors: {
    ais: null,
    cameras: null,
    sensorInfo: null,
  },
  sensorsEdge: {
    liveStreamUrl: (sensorId: string | null) => [{ sensorId }],
  },
  myFilters: {
    getMyFilters: null,
  },
  activities: {
    summary: filtersQuery,
    blacklistById: getDetailsById,
    sections: getDetailsById,
    placement: getDetailsById,
  },
  user: {
    permissions: null,
    info: null,
    byID: (id: string) => [id],
    userFilterOptions: null,
    userCount: null,
  },
  weather: {
    "weather-info": (vesselID: string | undefined, start_dttm: string | undefined, end_dttm: string | undefined) => [
      { vesselID, start_dttm, end_dttm },
    ],
  },
  extendedTimeline: {
    "beaufort-timeline": (vesselID: string | undefined, start_dttm: string | undefined, end_dttm: string | undefined) => [
      { vesselID, start_dttm, end_dttm },
    ],
    "visibility-timeline": (vesselID: string | undefined, start_dttm: string | undefined, end_dttm: string | undefined) => [
      { vesselID, start_dttm, end_dttm },
    ],
    "daynight-timeline": (vesselID: string | undefined, start_dttm: string | undefined, end_dttm: string | undefined) => [
      { vesselID, start_dttm, end_dttm },
    ],
  },
  notifications: {
    all: null,
    emailConfig: getDetailsById,
    config: null,
  },
  useComments: {
    comments: (id: string | undefined) => [{ id }] as const,
  },
  tags: {
    getTags: null,
  },
  // queryKeys defined in this section will NOT be refreshed automatically
  manualRefresh: {
    // Don't use this key, defined just for the type-safety in invalidateQueries predicate
    all: null,

    // User management page
    userList: (filters: {
      status: string;
      role: string[];
      fleet: string[];
      company: string[];
      ship_manager: string[];
      designaion: string[];
      sort: string;
      order: string;
    }) => [filters],
    sensorNames: getDetailsById,
    engineRoomVesselActivities: (start_dttm: string | undefined, end_dttm: string | undefined, vessel_id: string | undefined, fleet_id: string) => [
      { start_dttm, end_dttm, vessel_id, fleet_id },
    ],
    engineRoomVesselHeatMap: (start_dttm: string | undefined, end_dttm: string | undefined, camera_id: string[] | undefined) => [
      { start_dttm, end_dttm, camera_id },
    ],
    engineRoomVesselCompartment: (options: { name: EngineRoomType; vessel: string | undefined; start_dttm: string; end_dttm: string }) => [
      { options },
    ],
    engineRoomFleetActivities: (start_date: string | undefined, end_date: string | undefined, fleets: (string | undefined)[]) => [
      { start_date, end_date, fleets },
    ],
    engineRoomFleetSingleActivity: (activity: string, severity: Severity, filter: Pick<FleetReportFilter, "dateRange"> & { fleetID: string }) => [
      { activity, severity, filter },
    ],
    bridgeCompliant: (options: {
      name: string | string[];
      vesselId: string | undefined;
      start_dttm: string;
      end_dttm: string;
      severity?: string[];
      vesselType?: string[];
    }) => [{ options }],
    ppeReport: (options: { vessel: string[]; area: string; compare_to: string }) => [{ options }],
    ppeReportFleet: (options: { fleetsIds: string[]; area: string }) => [{ options }],
    ppeFleetVessels: (options: { fleetId: string | undefined; area: string; date: Date | undefined }) => [{ options }],
    genericReport: (options: {
      topic: string;
      kpi: string;
      start_dttm: string;
      end_dttm: string;
      fleetIds: string[];
      benchmarkType: BenchmarkType[];
    }) => [{ options }],
    genericVesselReport: (options: {
      topic: string;
      kpi: string;
      start_dttm: string;
      end_dttm: string;
      vessel_ids: string[];
      fleetIds: string[];
      benchmarkType: BenchmarkType[];
    }) => [{ options }],
  },
  detectors: {
    detectorsList: null,
  },
});
