import { CSSProperties } from "react";

import { ErrorContainer } from "./Charts.styles";
import ErrorIcon from "components/Icon/ErrorIcon";
import { color } from "config/variable.styles";

type FontSize = "small" | "default" | "medium" | "xs" | "xxs";
interface ErrorProps {
  size?: FontSize;
  height?: number;
  variant?: "error" | "nodata";
  text?: string;
  element?: "h3" | "p";
  heatMap?: boolean;
}
const fontSizes: Record<FontSize, string> = {
  xxs: "0.9rem",
  xs: "1rem",
  small: "1.25rem",
  medium: "1.375rem",
  default: "1.5rem",
};
const Error = (props: ErrorProps) => {
  const { size = "default", height, variant = "error", text = "Data", element = "h3", heatMap = false } = props;
  const style: CSSProperties = {
    fontSize: fontSizes[size],
    ...(element === "p" ? { fontWeight: 500 } : heatMap ? { fontWeight: 300 } : { fontWeight: 600 }),
  };
  const isError = variant === "error";
  const textContent = isError ? "An error occurred retrieving the data" : `No ${text} for current settings`;
  const Component = element;

  return (
    <ErrorContainer small={["small", "xs", "xxs"].includes(size)} height={height} style={{ backgroundColor: heatMap ? color.graywhiteTwo : "" }}>
      {isError ? <ErrorIcon /> : null}
      <Component className="text" style={style}>
        {textContent}
      </Component>
    </ErrorContainer>
  );
};

export { Error };
