import { useLayoutEffect, useState, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { Error } from "./Error";
import { EngineRoomBridgeWatchChartProps } from "./Charts.types";
import { addDays, differenceInHours } from "date-fns";
import { getOptions } from "components/Timelines/utils";
import { formatDate } from "utils";
import { EngineRoomReportsAmchartsObject } from "types/Reports.types";
import PopperPopover from "components/PopperPopover";
import { VirtualElement } from "@popperjs/core";
import TimelineTooltip from "components/Timelines/TimelineTooltip/TimelineTooltip";
import { rgba } from "config/variable.styles";
import useOutsideHover from "hooks/useOutsideHover";
import { Typography } from "@material-ui/core";
import { navigationTimelineTitle } from "queries";
import { timelineLegends } from "config/images";
import Tooltip from "components/Tooltip";

const CompartmentBridgeWtatch = (props: EngineRoomBridgeWatchChartProps) => {
  const { containerID, data, error, width = "10vw", dateAxisvisible = false, label, start = "", end = "", enableTooltip = false, vessel } = props;
  const hoveredEventBar = useRef<any>(null);
  const selected = useRef<any>(null);
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const mouseOut = useRef(() => {
    setReferenceElement(null);
    setSelectedEvent(null);
    if (hoveredEventBar.current) {
      hoveredEventBar.current.removeAttribute("stroke-width");
      hoveredEventBar.current.removeAttribute("stroke-opacity");
      hoveredEventBar.current.removeAttribute("stroke-linejoin");
      hoveredEventBar.current.style.borderRadius = null;
      hoveredEventBar.current.style.outline = null;
      hoveredEventBar.current.style.outlineColor = null;
    }
  });
  const [selectedEvent, setSelectedEvent] = useState<EngineRoomReportsAmchartsObject | null>(null);
  const [referenceElement, setReferenceElement] = useState<Element | VirtualElement | null>(null);
  const totalActivities = Object.values(selectedEvent?.data ?? {}).reduce((acc, curr) => acc + curr, 0);

  useOutsideHover(chartContainer, mouseOut.current);

  useLayoutEffect(() => {
    if (!data) return;

    const start_dttm = formatDate(start);
    const end_dttm = formatDate(end, "yyyy-MM-dd'T'23:59:59");

    const difference = differenceInHours(addDays(new Date(end), 1), new Date(start));
    const { resolution } = getOptions(difference);

    const chart = am4core.create(containerID, am4charts.XYChart);
    chart.data = data;
    chart.zoomOutButton.disabled = true;
    chart.paddingRight = 15;
    chart.paddingLeft = 10;
    chart.paddingBottom = 0;

    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.opacity = 0;
    categoryAxis.dataFields.category = "type";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.fill = am4core.color("#8DB0D4");
    categoryAxis.fillOpacity = 1;
    categoryAxis.renderer.labels.template.height = 30;
    categoryAxis.renderer.labels.template.disabled = !false;
    categoryAxis.renderer.labels.template.fontSize = 12;
    categoryAxis.renderer.labels.template.fontWeight = "100";
    categoryAxis.renderer.labels.template.stroke = am4core.color("#000");

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.dateFormats.setKey("hour", "yyyy-MM-dd");
    dateAxis.periodChangeDateFormats.setKey("hour", "yyyy-MM-dd");
    dateAxis.dateFormats.setKey("day", "yyyy-MM-dd");
    dateAxis.periodChangeDateFormats.setKey("day", "yyyy-MM-dd");
    dateAxis.dateFormats.setKey("month", "yyyy-MM-dd");
    dateAxis.periodChangeDateFormats.setKey("month", "yyyy-MM-dd");
    dateAxis.dateFormats.setKey("week", "yyyy-MM-dd");
    dateAxis.periodChangeDateFormats.setKey("week", "yyyy-MM-dd");
    dateAxis.baseInterval = {
      timeUnit: "hour",
      count: resolution.hours,
    };
    //@ts-ignore
    dateAxis.renderer.grid.template.location = 0.2;
    dateAxis.renderer.grid.template.opacity = 1;
    dateAxis.renderer.grid.template.visible = dateAxisvisible;
    //@ts-ignore
    dateAxis.renderer.ticks.template.location = 0.2;
    //@ts-ignore
    dateAxis.renderer.labels.template.location = 0.2;
    dateAxis.renderer.grid.template.opacity = 0;
    dateAxis.renderer.labels.template.fontSize = 12;
    dateAxis.renderer.labels.template.dy = 12;
    dateAxis.renderer.labels.template.visible = dateAxisvisible;

    dateAxis.renderer.ticks.template.above = true;
    dateAxis.renderer.ticks.template.disabled = !dateAxisvisible;
    dateAxis.renderer.ticks.template.strokeOpacity = 1;
    dateAxis.renderer.ticks.template.dy = 10;
    dateAxis.renderer.ticks.template.length = 10;
    dateAxis.renderer.ticks.template.stroke = am4core.color("#d6dce5");
    dateAxis.startLocation = 0;
    dateAxis.endLocation = 1;
    dateAxis.max = new Date(end_dttm).getTime();
    dateAxis.min = new Date(start_dttm).getTime();

    const series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.paddingRight = 10;
    series1.dataFields.openDateX = "amc_fromDate";
    series1.dataFields.dateX = "amc_toDate";
    series1.dataFields.categoryY = "type";
    series1.columns.template.column.propertyFields.fill = "color";
    series1.columns.template.column.propertyFields.stroke = "color";
    series1.columns.template.column.strokeOpacity = 1;
    series1.columns.template.clickable = true;
    series1.columns.template.height = 10;
    series1.background.fill = am4core.color("#EFEFEF");
    series1.background.fillOpacity = 1;

    // Add a container for the background
    const backgroundContainer = chart.plotContainer.createChild(am4core.Container);
    backgroundContainer.width = am4core.percent(100);
    backgroundContainer.height = am4core.percent(100);
    backgroundContainer.background.fill = am4core.color("#EFEFEF");

    // Move the series to the front
    chart.series.moveValue(series1, chart.series.length - 1);

    const handleHover = (e: any) => {
      e.event.stopPropagation();
      if (hoveredEventBar.current) {
        hoveredEventBar.current.removeAttribute("stroke-width");
        hoveredEventBar.current.removeAttribute("stroke-opacity");
        hoveredEventBar.current.removeAttribute("stroke-linejoin");
        hoveredEventBar.current.style.borderRadius = null;
        hoveredEventBar.current.style.outline = null;
        hoveredEventBar.current.style.outlineColor = null;
      }
      setReferenceElement(e.event.target);
      if (e.target.dataItem?.dataContext.color === "#EFEFEF") return;
      selected.current = e.target.dataItem?.dataContext;
      setSelectedEvent(e.target.dataItem?.dataContext);

      hoveredEventBar.current = e.event.target;
      const color = hoveredEventBar.current?.parentElement?.getAttribute("stroke");
      hoveredEventBar.current?.setAttribute("stroke-width", "3.5");
      hoveredEventBar.current?.setAttribute("stroke-opacity", "0.5");
      hoveredEventBar.current?.setAttribute("stroke-linejoin", "round");
      hoveredEventBar.current.style.borderRadius = "15px";
      hoveredEventBar.current.style.outline = "solid 4px";
      hoveredEventBar.current.style.outlineColor = rgba(color, 0.5);
    };

    const handleOut = () => {
      if (selected.current) {
        mouseOut.current();
      }
    };

    series1.columns.template.events.on("over", handleHover);

    chart.events.on("over", handleOut);

    return () => {
      chart.dispose();
    };
  }, [data, containerID, dateAxisvisible, end, start]);

  return (
    <>
      <div style={{ width: "100%", display: "flex", alignItems: "center", fontSize: "14px" }}>
        <div style={{ width: width, marginBottom: "-12px" }}>
          <Tooltip
            disableHoverListener={!label}
            arrow
            title={
              <div style={timelineLegends["navigation"].style}>
                <img className="timelegends" src={timelineLegends["navigation"].image} alt={""} style={{ width: "100%", height: "auto" }} />
              </div>
            }
            placement="top-start"
          >
            <span style={{ width: "max-content" }}>{label ?? data?.[0]?.type}</span>
          </Tooltip>
        </div>
        {error || !data?.length ? (
          <div style={{ width: `calc(100vw - ${width} )`, height: "63px", marginBottom: "-34px" }}>
            <Error variant={"error"} size="xs" height={50} element="p" />
          </div>
        ) : (
          <div id={containerID} ref={chartContainer} style={{ width: `calc(100vw - ${width} )`, height: "63px", marginBottom: "-34px" }} />
        )}
      </div>
      {!!selectedEvent && enableTooltip ? (
        <PopperPopover
          noArrow
          className="timeline-popover"
          referenceElement={referenceElement}
          placement="top-start"
          borderColor={selectedEvent.color}
        >
          <TimelineTooltip
            vessel={vessel}
            from_dttm={selectedEvent.amc_fromDate}
            to_dttm={selectedEvent.amc_toDate}
            onClickAway={mouseOut.current}
            headerPrefix={
              selectedEvent.type === "Navigation Status" ? null : (
                <>
                  <strong>{totalActivities}</strong> activities during{" "}
                </>
              )
            }
          >
            {selectedEvent.type === "Navigation Status" ? (
              <TimelineTooltip.Section maxwidth={360}>
                <Typography style={{ fontSize: "14px" }}>
                  {selectedEvent.type}: <strong>{navigationTimelineTitle[selectedEvent.value ?? "OTHER"]}</strong>
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  {!!selectedEvent.port ? <>Current Port: {<strong>{selectedEvent.port} </strong>}</> : null}
                </Typography>
              </TimelineTooltip.Section>
            ) : (
              <>
                <TimelineTooltip.Section maxwidth={120}>
                  <TimelineTooltip.Field title="Routine" count={selectedEvent.data?.Routine} />
                </TimelineTooltip.Section>
                <TimelineTooltip.Section maxwidth={120}>
                  <TimelineTooltip.Field title="Attention" count={selectedEvent.data?.Attention} />
                </TimelineTooltip.Section>
                <TimelineTooltip.Section maxwidth={120}>
                  <TimelineTooltip.Field title="Alert" count={selectedEvent.data?.Alert} />
                </TimelineTooltip.Section>
              </>
            )}
          </TimelineTooltip>
        </PopperPopover>
      ) : null}
    </>
  );
};

export default CompartmentBridgeWtatch;
