import { subDays } from "date-fns";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FleetReportFilter } from "types/FilterBar.types";
import { isEqual } from "lodash";

const initialState: FleetReportFilter = {
  fleet: { name: "", id: "" },
  dateRange: {
    start: subDays(new Date(), 29).toISOString(),
    end: new Date().toISOString(),
  },
  compare_to: [],
};
const engineFleetFilter = createSlice({
  name: "engineFleetFilter",
  initialState,
  reducers: {
    setEngFleetDateRange(state, action: PayloadAction<typeof initialState.dateRange>) {
      state.dateRange = action.payload;
    },
    setEngineFleetFilter(state, action: PayloadAction<typeof initialState.fleet>) {
      state.fleet = action.payload;
    },
    setEngFleetCompareToFilter(state, action: PayloadAction<typeof initialState.compare_to>) {
      // If current and next state are same, don't update anything in slice.
      const currentState = [...state.compare_to].sort();
      const nextState = [...action.payload].sort();
      if (isEqual(currentState, nextState)) return;
      state.compare_to = action.payload;
    },
  },
});

export const { setEngFleetDateRange, setEngineFleetFilter, setEngFleetCompareToFilter } = engineFleetFilter.actions;
export default engineFleetFilter.reducer;
