import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import { VesselReportFilter } from "types/FilterBar.types";
import { isEqual } from "lodash";

const initialState: VesselReportFilter = {
  vessel: undefined,
  dateRange: {
    start: subDays(new Date(), 29).toISOString(),
    end: new Date().toISOString(),
  },
  compare_to: "INDUSTRY_BENCHMARK",
};

const marpolFilter = createSlice({
  name: "marpolFilter",
  initialState,
  reducers: {
    setMarpolVessel(state, action: PayloadAction<typeof initialState.vessel>) {
      state.vessel = action.payload;
    },
    setMarpolDate(state, action: PayloadAction<typeof initialState.dateRange>) {
      state.dateRange = action.payload;
    },
    setMarpolCompareTo(state, action: PayloadAction<typeof initialState.compare_to>) {
      // If current and next state are same, don't update anything in slice.
      const currentState = [...state.compare_to].sort();
      const nextState = [...action.payload].sort();
      if (isEqual(currentState, nextState)) return;
      state.compare_to = action.payload;
    },
  },
});

export const { setMarpolCompareTo, setMarpolDate, setMarpolVessel } = marpolFilter.actions;
export default marpolFilter.reducer;
