import { ReactNode } from "react";
import { differenceInHours, format, subDays } from "date-fns";
import { ClickAwayListener } from "@material-ui/core";

import { useVessels } from "hooks";

import { AllEventTooltipStyle, TimelineFieldContainer } from "./TimelineTooltip.styles";

interface TimelineFieldProps {
  title: string;
  count: number | undefined;
}
const TimelineField = (props: TimelineFieldProps) => {
  const { title, count = 0 } = props;

  return (
    <div className={`event-icon__item ${!count ? "event-icon__item--disable" : ""}`.trim()}>
      <p>{title}</p>
      <span className="count">{count}</span>
    </div>
  );
};

interface TimelineTooltipProps {
  onClickAway: () => void;
  vessel?: string;
  children: ReactNode;
  headerPrefix?: ReactNode;
  from_dttm: Date | undefined;
  to_dttm: Date | undefined;
}
const TimelineTooltip = (props: TimelineTooltipProps) => {
  const { from_dttm, to_dttm, onClickAway, vessel, children, headerPrefix } = props;
  const { data } = useVessels(vessel);
  const vesselInfo = !Array.isArray(data) ? data : undefined;

  const renderHead = () => {
    if (!from_dttm || !to_dttm) return null;

    const toDate = to_dttm ?? new Date();
    const fromDate = from_dttm ?? new Date();

    // If timeline bin holds data for larger range ( >= 1 day),
    // show start and end days and hide time.
    const range = differenceInHours(toDate, fromDate);
    const isLargeRange = range >= 24;
    const endDate = range > 24 ? subDays(toDate, 1) : toDate;

    const formatter = {
      startDate: isLargeRange ? "LLL dd" : "LLL dd', ' HH:mm - ",
      endDate: isLargeRange ? "'-' LLL dd" : "HH:mm",
    };

    return (
      <h4 className="head__info">
        {headerPrefix}
        {`${format(fromDate, formatter.startDate)} ${range === 24 ? "" : format(endDate, formatter.endDate)}`.trim()}
      </h4>
    );
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <AllEventTooltipStyle>
        <div className="head">
          <h3 className="head__title">{vesselInfo?.name ?? "-"}</h3>
          {renderHead()}
        </div>
        <div className="body">
          <div className="event-icon">{children}</div>
        </div>
      </AllEventTooltipStyle>
    </ClickAwayListener>
  );
};

TimelineTooltip.Field = TimelineField;
TimelineTooltip.Section = TimelineFieldContainer;

export default TimelineTooltip;
