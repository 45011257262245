import { useMemo } from "react";
import { useAppSelector } from "hooks";

import { useReportsContext } from "../utils";

interface LegendsDisplayProps {
  EngineRoomFleet?: boolean;
}
const LegendsDisplay = (props: LegendsDisplayProps) => {
  const { EngineRoomFleet = false } = props;
  const { colors, fleetNames } = useReportsContext();
  const selectedFleet = useAppSelector((state) => (EngineRoomFleet ? state.engineFleetFilter.compare_to : state.reportsFilter.compare_to));
  const selectedFleet2 = useAppSelector((state) => (EngineRoomFleet ? state.engineFleetFilter.fleet : state.reportsFilter.fleet));

  /**
   * Converting the colors object to a Record
   * with the following structure:
   * {
   *  color: [fleetName1, fleetName2, ...]
   * }
   */
  const result = useMemo(() => {
    const selectedFleets = [...selectedFleet, ...(!!selectedFleet2?.id ? [selectedFleet2?.id] : [])];

    return Object.entries(colors).reduce<Record<string, string[]>>((result, item) => {
      const [fleet_id, selectedColor] = item;
      if (!selectedFleets.includes(fleet_id)) return result;

      const fleetName = fleetNames[fleet_id];
      const array = result[selectedColor] || [];
      array.push(fleetName);
      result[selectedColor] = array;

      return result;
    }, {});
  }, [colors, fleetNames, selectedFleet, selectedFleet2?.id]);

  return (
    <div className="customLegendRow">
      <ul className="chartCustomLegend">
        {Object.entries(result).map(([backgroundColor, value], index) =>
          value.length > 0 ? (
            <li key={index}>
              <span className="color-box" style={{ backgroundColor }} />
              {value.join(", ")}
            </li>
          ) : null
        )}
        <li>
          <span className="color-box bgRed" /> Benchmark
        </li>
      </ul>
    </div>
  );
};

export { LegendsDisplay };
