import { color, rem } from "config/variable.styles";
import styled from "styled-components";

export const LegendsStyles = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 ${rem(0)};
  padding: 0;
  &.vertical {
    flex-direction: column;
    align-items: flex-start;
    gap: ${rem(12)};
  }
  li {
    margin-left: ${rem(20)};
    font-size: ${rem(12)};
    color: ${color.DarkBlack};
    font-weight: 600;
    list-style: none;
    display: flex;
    align-items: center;
    .color-box {
      display: inline-block;
      width: ${rem(12)};
      height: ${rem(12)};
      background-color: ${color.coral};
      margin-right: ${rem(10)};
      &.bgFireEngineRed {
        background-color: ${color.fireEngineRedColor};
      }
      &.bgPaleOrange {
        background-color: ${color.paleOrange};
      }
      &.bgAquaMarine {
        background-color: ${color.aquaMarine};
      }
    }
  }
  &.left-border {
    border-left: ${rem(1)} solid ${color.graywhiteTwo} !important;
    margin-left: ${rem(48)};
    padding-left: ${rem(31)};
  }
`;
