import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

interface ppeReportFilterTypes {
  vessel: string[];
  area: string;
  compare_to: string;
  isClear: boolean;
}
const initialState: ppeReportFilterTypes = {
  vessel: [],
  area: "All",
  compare_to: "INDUSTRY_BENCHMARK",
  isClear: false,
};

const ppeReportFilter = createSlice({
  name: "ppeReportFilter",
  initialState,
  reducers: {
    setPPEVesselFilter(state, action: PayloadAction<typeof initialState.vessel>) {
      // If current and next state are same, don't update anything in slice.
      const currentState = [...state.vessel].sort();
      const nextState = [...action.payload].sort();
      if (isEqual(currentState, nextState)) return;
      state.vessel = action.payload;
      if (!!action.payload.length) state.isClear = false;
    },
    setPPEAreaFilter(state, action: PayloadAction<typeof initialState.area>) {
      state.area = action.payload;
    },
    setPPECompareToFilter(state, action: PayloadAction<typeof initialState.compare_to>) {
      // If current and next state are same, don't update anything in slice.
      const currentState = [...state.compare_to].sort();
      const nextState = [...action.payload].sort();
      if (isEqual(currentState, nextState)) return;
      state.compare_to = action.payload;
    },
    setClearState(state) {
      state.isClear = true;
    },
  },
});

export const { setPPEVesselFilter, setPPECompareToFilter, setPPEAreaFilter, setClearState } = ppeReportFilter.actions;
export default ppeReportFilter.reducer;
