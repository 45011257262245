import styled from "styled-components";
import { rem, color, xl } from "../../config/variable.styles";

export const InfoSummaryWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoSummaryWidgetBody = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  .summaryWidgetDeta {
    width: 67%;
    margin-left: auto;
    ul {
      width: 100%;
      margin: ${rem(0)};
      padding: ${rem(0)};
      list-style: none;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-wrap: wrap;
      li {
        display: flex;
        flex-direction: column;
        width: 22.66%;
        max-width: ${rem(110)};
        margin: ${rem(0)} ${rem(0)} ${rem(25)} ${rem(9)};
        margin-bottom: 25px;
        margin-left: 0.5625rem;
        .summaryWidgetDetaTitle {
          font-size: ${rem(24)};
          font-weight: 900;
          line-height: normal;
          letter-spacing: normal;
          color: ${color.DarkBlack};
          margin: ${rem(0)};
          display: flex;
          align-items: flex-end;
          ${xl(`
            font-size: ${rem(16)};
          `)}
        }
        .summaryWidgetBg {
          width: 100%;
          height: ${rem(12)};
          margin: ${rem(5)} 0 0 0;
          border-radius: ${rem(2)};
          display: block;
        }
        .summaryWidgetLabel {
          font-size: ${rem(12)};
          font-weight: 400;
          line-height: normal;
          color: ${color.DarkBlack};
          margin: ${rem(3)} 0 0;
        }
        &.disable {
          .summaryWidgetDetaTitle {
            color: ${color.graywhiteTwo};
          }
          .summaryWidgetBg {
            background: ${color.graywhiteTwo} !important;
          }
        }
        &.benchmark-range {
          width: 32%;
          max-width: ${rem(155)};
          margin-right: ${rem(31)};
          white-space: nowrap;
          margin-left: 0;
        }
      }
    }
  }
`;

export const SummaryWidgetError = styled.div`
  display: none;
  align-items: center;
  padding: 5px 0 0;
  .summaryWidgetDeta {
    width: 100%;
    ul {
      margin: ${rem(0)};
      padding: ${rem(0)};
      list-style: none;
      display: flex;
      align-items: center;
      li {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: ${rem(0)} ${rem(9)} ${rem(0)} ${rem(0)};
        &:last-child {
          margin: ${rem(0)};
        }
        .summaryWidgetDetaTitle {
          font-size: ${rem(24)};
          font-weight: 900;
          line-height: normal;
          letter-spacing: normal;
          color: ${color.DarkBlack};
          margin: ${rem(0)};
          display: flex;
          align-items: flex-end;
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }
        .widgetErrorMessage {
          display: flex;
          align-items: center;
          width: 100%;
          flex: 0 0 100%;
          max-width: 100%;
          justify-content: space-between;
          margin-top: ${rem(10)};
          .errorMessage {
            h3 {
              font-size: ${rem(18)};
              font-weight: 700;
              line-height: normal;
              color: ${color.DarkBlack};
              margin: 0 0 ${rem(5)};
            }
            p {
              font-size: ${rem(13)};
              font-weight: normal;
              line-height: normal;
              color: ${color.DarkBlack};
              margin: ${rem(0)};
            }
          }
          .errorIcon {
            padding-left: ${rem(10)};
          }
        }
      }
    }
  }
`;
