import { useLayoutEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { VirtualElement } from "@popperjs/core";
import { ChartContainer } from "./Charts.styles";
import { XYChartProps, XYChartData } from "./Charts.types";
import { Error } from "./Error";
import Skeleton from "components/Loaders/Skeleton";
import PopperPopover from "components/PopperPopover";
import GraphTooltip from "components/Reports/GraphTooltip";
import { useReportsContext } from "components/Reports";

am4core.options.autoDispose = true;

const XYChart = (props: XYChartProps) => {
  const { data, containerID, benchmarkRange, loading, error, onSelect, start, end } = props;
  const { fleetNames } = useReportsContext();
  const selectedBar = useRef<any>(null);
  const onSelectRef = useRef(onSelect);
  const dataRef = useRef({ data, benchmarkRange });
  const [tooltip, setTooltip] = useState<{ data: XYChartData[number]; referenceElement: Element | VirtualElement } | null>(null);

  useLayoutEffect(() => {
    dataRef.current = { data, benchmarkRange };
  });

  useLayoutEffect(() => {
    if (!dataRef.current.data || !dataRef.current.data.length || loading || error) return;

    const chart = am4core.create(containerID, am4charts.XYChart);
    chart.dateFormatter.dateFormat = "yyyy-MM-dd";
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    chart.maskBullets = false;

    chart.height = am4core.percent(100);
    chart.marginTop = 65;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.visible = false;
    dateAxis.renderer.labels.template.location = 0.5;
    dateAxis.renderer.labels.template.fontSize = 12;
    dateAxis.baseInterval = {
      timeUnit: "day",
      count: 1,
    };
    dateAxis.dateFormats.setKey("day", "yyyy-MM-dd");
    dateAxis.periodChangeDateFormats.setKey("day", "yyyy-MM-dd");
    dateAxis.dateFormats.setKey("hour", "yyyy-MM-dd");
    dateAxis.periodChangeDateFormats.setKey("hour", "yyyy-MM-dd");
    dateAxis.dateFormats.setKey("week", "yyyy-MM-dd");
    dateAxis.periodChangeDateFormats.setKey("week", "yyyy-MM-dd");
    dateAxis.dateFormats.setKey("month", "yyyy-MM-dd");
    dateAxis.periodChangeDateFormats.setKey("month", "yyyy-MM-dd");
    dateAxis.renderer.line.strokeOpacity = 1;
    dateAxis.renderer.line.stroke = am4core.color("#d6dce5");
    dateAxis.renderer.ticks.template.above = true;
    dateAxis.renderer.ticks.template.disabled = false;
    dateAxis.renderer.ticks.template.strokeOpacity = 1;
    dateAxis.renderer.ticks.template.location = 0.5;
    dateAxis.renderer.ticks.template.stroke = am4core.color("#d6dce5");
    dateAxis.renderer.ticks.template.dy = -12;
    dateAxis.renderer.ticks.template.length = 12;
    dateAxis.startLocation = 0.1;
    dateAxis.endLocation = 1;

    //to force graph to have all dates even though we don't have datapoints for that period
    if (start && end) {
      dateAxis.min = new Date(start).getTime(); // Set the minimum date as a timestamp
      dateAxis.max = new Date(end).getTime(); // Set the maximum date as a timestamp
    }

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.visible = false;
    valueAxis.renderer.labels.template.fontSize = 12;
    valueAxis.renderer.labels.template.location = 0;
    valueAxis.renderer.ticks.template.location = 0;
    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.stroke = am4core.color("#d6dce5");
    valueAxis.max = 100;

    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "value";
    series.dataFields.dateX = "date";
    series.name = "value";
    series.tooltipText = "{dateX.formatDate('yyyy-dd')}: {valueY.formatNumber('#.00')}";
    series.strokeWidth = 2;
    series.calculatePercent = true;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });

    function createSeries(data: XYChartData, color: string) {
      const series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.dateX = "date";
      series1.dataFields.valueY = "value";
      series1.stroke = am4core.color(color);
      series1.strokeWidth = 3;
      series1.data = data;
      series1.showOnInit = false;
      series1.defaultState.transitionDuration = 0;
      series1.hiddenState.transitionDuration = 0;
      series1.interpolationDuration = 0;
      const bullet = series1.bullets.push(new am4charts.CircleBullet());
      bullet.fill = am4core.color(color);
      bullet.height = 24;
      bullet.width = 24;
      bullet.circle.radius = 6;
      bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      bullet.events.on("over", (e: any) => {
        e.event.stopPropagation();
        setTooltip({ referenceElement: e.event.target, data: e.target.dataItem?.dataContext });
      });
      bullet.events.on("out", () => setTooltip(null));
      bullet.events.on("down", (e: any) => {
        e.event.stopPropagation();
        const dataItem = e.target.dataItem?.dataContext;
        if (selectedBar.current && e.event.target === selectedBar.current) {
          selectedBar.current?.setAttribute("r", 6);
          chart.background.opacity = 0;
          selectedBar.current = null;
          onSelectRef.current?.(null);
          return;
        }
        selectedBar.current?.setAttribute("r", 6);
        selectedBar.current = e.event.target;
        onSelectRef.current?.(dataItem);
        selectedBar.current?.setAttribute("r", 10);
      });
    }

    function createEmptySeries(field: string) {
      const emptySeries = chart.series.push(new am4charts.LineSeries());
      emptySeries.data = dataRef.current.benchmarkRange;
      emptySeries.dataFields.valueY = field;
      emptySeries.dataFields.dateX = "date";
      emptySeries.stroke = am4core.color("#eb2c62");
      emptySeries.name = "";
      emptySeries.tooltipText = "{dateX.formatDate('yyyy-dd')}: {valueY.formatNumber('#.00')}";
      emptySeries.strokeWidth = 1;
      emptySeries.calculatePercent = true;
      emptySeries.strokeDasharray = "5,5";
    }

    dataRef.current.data.forEach((item) => createSeries(item, item[0].color));

    createEmptySeries("industry_benchmark_lower");
    createEmptySeries("industry_benchmark_upper");

    return () => {
      chart.dispose();
    };
  }, [containerID, end, error, loading, start]);

  if (error || (!data?.length && !loading)) {
    return (
      <ChartContainer>
        <Error variant={error ? "error" : "nodata"} height={400} />
      </ChartContainer>
    );
  }

  return (
    <ChartContainer>
      {!!tooltip && (
        <PopperPopover
          backgroundColor="transparent"
          className="reports-popover"
          referenceElement={tooltip.referenceElement}
          placement="top-start"
          noborder
        >
          <GraphTooltip
            values={[
              { title: "Fleet", value: fleetNames[tooltip.data.fleet_id] },
              { title: "KPI", value: tooltip.data.value?.toFixed(1) },
            ]}
          />
        </PopperPopover>
      )}
      <Skeleton loading={loading} animation="wave" height={390} width="100%">
        <div id={containerID} style={{ height: "365px" }} />
      </Skeleton>
    </ChartContainer>
  );
};

export { XYChart };
