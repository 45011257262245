/* eslint-disable max-lines */
import { Box, Button, Grid, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { color, rem } from "config/variable.styles";
import styled from "styled-components";

export const ProfileFormStyle = styled.div`
  display: flex;
  flex: 1 1 auto;
  padding: ${rem(20)} 0;
  .form-row {
    display: flex;
    flex: 1;
  }
  .left-col {
    width: ${rem(280)};
    max-width: ${rem(280)};
    flex: 0 0 ${rem(280)};
  }
  .right-col {
    flex: 1 1 auto;
    width: calc(100% - ${rem(280)});
    max-width: calc(100% - ${rem(280)});
    padding: ${rem(0)} ${rem(0)} ${rem(0)} ${rem(170)};
    .right-col-wrapper {
      max-width: 100%;
      margin-top: ${rem(25)};
      .informationList {
        display: flex;
        flex-direction: column;
        width: 100%;
        .informationRow {
          display: flex;
          align-items: center;
          margin-bottom: ${rem(20)};
          height: ${rem(35)};
          flex-wrap: wrap;
          &:last-child {
            margin-bottom: 0px;
          }
          .informationLabel {
            margin: 0;
            width: ${rem(170)};
            max-width: ${rem(170)};
            flex: 0 0 ${rem(170)};
            font-weight: 600;
            font-size: ${rem(14)};
          }
          .information {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: ${rem(14)};
            .editProfileName {
              position: relative;
              .MuiInput-formControl {
                .MuiInput-input {
                  border: ${rem(1)} solid rgb(223, 223, 223);
                  padding: 0 ${rem(15)};
                  height: ${rem(33.5)};
                  font-size: ${rem(14)};
                }
                &:after,
                &:before {
                  display: none;
                }
              }
              .MuiFormHelperText-root {
                color: ${color.redColor};
                position: absolute;
                bottom: ${rem(-20)};
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
  .form-group {
    margin-bottom: ${rem(19)};
    &.mrb-34 {
      margin-bottom: ${rem(34)};
    }
    .MuiFormControl-root {
      width: 100%;
      display: flex;
      position: relative;
      align-items: flex-start;
      &.not-valid {
        .MuiInputBase-root {
          .MuiInputBase-input {
            border-color: ${color.coral};
          }
        }
      }
      .MuiFormLabel-root {
        width: auto;
        font-size: ${rem(11)};
        font-weight: 700;
        color: ${color.DarkBlack};
        text-transform: uppercase;
        transform: translate(0, 0) scale(1);
        position: relative;
        margin: 0 0 ${rem(5)};
        display: inline !important;
        align-items: center;
        padding-right: ${rem(14)};
        &:hover {
          .edit-control {
            visibility: visible;
          }
        }
        .edit-control {
          position: absolute;
          right: ${rem(-8)};
          top: ${rem(-6)};
          .icon-edit {
            font-size: ${rem(18)};
          }
        }
      }
      .form-view-control {
        min-height: ${rem(40)};
        font-size: ${rem(14)};
        line-height: ${rem(28)};
        color: ${color.DarkBlack};
      }
      .save-control {
        min-height: ${rem(40)};
        display: flex;
        align-items: center;
      }
      .MuiInputBase-root {
        width: 100%;
        display: block;
        margin: 0;
        &:after,
        &:before {
          display: none;
        }
        .MuiInputBase-input {
          background-color: ${color.White};
          height: ${rem(40)};
          box-sizing: border-box;
          padding: ${rem(6)} ${rem(20)};
          border: ${rem(1)} solid ${color.White};
          font-size: ${rem(14)};
          color: ${color.DarkBlack};
          line-height: ${rem(28)};
        }
        .icon-chevron {
          top: calc(50% - ${rem(8)});
          color: ${color.Black};
          right: ${rem(10)};
          position: absolute;
          pointer-events: none;
          display: flex;
        }
      }
      .MuiAutocomplete-root {
        .MuiInputBase-input {
          width: 100%;
        }
      }
      .MuiFormHelperText-root {
        font-size: ${rem(11)};
        color: ${color.coral};
        line-height: ${rem(14)};
        margin: ${rem(5)} 0 0;
      }
    }
    &.profile-details-wrap {
      .MuiFormControl-root {
        display: flex;
        align-items: center;
        flex-direction: inherit;
        .MuiInputLabel-formControl {
          text-transform: capitalize;
          font-size: 14px;
          margin: 0;
        }
        .form-view-control {
          min-height: inherit;
        }
      }
    }
  }
`;
export const FormCol = styled.div`
  width: 100%;
  position: relative;
`;
export const FormContainer = styled.div`
  padding: ${rem(34)} ${rem(30)};
  position: relative;
`;

export const GridStyle = withStyles(
  {
    root: {
      width: `calc(100% + ${rem(20)})`,
      margin: `0 ${rem(-10)}`,
      "& .MuiGrid-item": {
        padding: `0 ${rem(10)}`,
      },
      "& .width300": {
        flexGrow: 0,
        maxWidth: rem(320),
        flexBasis: rem(320),
      },
      "& .width100": {
        flexGrow: 0,
        maxWidth: rem(140),
        flexBasis: rem(140),
      },
    },
  },
  { name: "profile-grid" }
)(Grid);

export const EditIconBtn = withStyles(
  {
    root: {
      width: "auto",
      padding: 0,
      height: "auto",
      background: "transparent",
      margin: `0 0 0 ${rem(10)}`,
      "&:hover": {
        background: "transparent",
      },
      "& .icon-edit": {
        display: "flex",
        width: "20px",
      },
    },
  },
  { name: "edit-icon-btn" }
)(IconButton);

export const SaveButton = withStyles(
  {
    root: {
      borderRadius: rem(25),
      border: `solid ${rem(2)} ${color.CloudyBlue}`,
      boxShadow: "inherit",
      backgroundColor: color.White,
      minWidth: rem(110),
      height: rem(32),
      padding: `0 0 ${rem(1)} 0`,
      margin: `0`,
      fontSize: rem(14),
      lineHeight: rem(17),
      fontWeight: 500,
      "&:hover": {
        boxShadow: "inherit",
        backgroundColor: color.White,
      },
      "& .MuiCircularProgress-colorPrimary": {
        color: color.DarkBlack,
        marginLeft: rem(10),
      },
    },
    label: {
      textTransform: "none",
    },
  },
  { name: "profile-save-btn" }
)(Button);

export const TabBoxStyle = withStyles(
  {
    root: {
      "& .tabHeaderBox": {
        minHeight: "inherit",
        "& .Tabs": {},
        "& .MuiTabs-scroller": {
          overflow: "inherit !important",
          padding: `0 0 ${rem(5)} 0`,
          position: "relative",
          "&:before": {
            content: '""',
            position: "absolute",
            bottom: rem(5),
            left: 0,
            right: 0,
            height: `${rem(4)}`,
            backgroundColor: color.tableBorderColorSolitude,
            width: "100%",
            zIndex: 0,
          },
          "& .tabHeaderList": {
            padding: `${rem(0)}`,
            maxWidth: "inherit",
            minWidth: "inherit",
            textTransform: "inherit",
            fontSize: rem(16),
            color: color.DarkBlack,
            marginRight: rem(30),
            minHeight: "inherit",
            position: "relative",
            paddingBottom: `${rem(5)}`,
            fontWeight: "bold",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: rem(0),
              left: 0,
              width: "100%",
              height: rem(4),
              backgroundColor: "transparent",
              zIndex: 2,
            },
            "&.Mui-selected": {
              opacity: "inherit",
              color: color.DarkRoyalBlue,
              "&::after": {
                backgroundColor: color.darkBlue,
              },
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
        },
      },
    },
  },
  { name: "profile-tab" }
)(Box);

export const NotificationContent = styled.div`
  padding: 0;
  margin-top: ${rem(30)};
  position: relative;
  height: 100%;
  h2 {
    margin: 0 0 ${rem(38)};
    font-size: ${rem(21)};
    font-weight: 800;
    color: ${color.DarkBlack};
  }
  .notificaitonsListBox {
    display: flex;
    align-items: flex-start;
    .notificaitonsListItem {
      width: ${rem(248)};
      strong {
        width: 100%;
        display: block;
        border-bottom: solid ${rem(1)} ${color.graywhiteTwo};
        padding-bottom: ${rem(7)};
        line-height: normal;
        position: relative;
        padding-left: ${rem(10)};
        &:after {
          content: "";
          position: absolute;
          top: ${rem(2)};
          left: 0;
          background: ${color.coral};
          width: ${rem(5)};
          height: ${rem(16)};
          border-radius: ${rem(2)};
        }
      }
      .checkboxList {
        padding: 0;
        margin: 0;
        .checkboxListItem {
          list-style: none;
          padding: ${rem(10)} 0;
          .MuiFormControlLabel-root {
            display: block;
            &:hover {
              .MuiCheckbox-root {
                color: ${color.darkBlue};
              }
            }
            .MuiCheckbox-root {
              color: ${color.darkBlue};
            }
          }
        }
      }
      &:last-child {
        strong {
          &:after {
            background: ${color.paleOrange};
          }
        }
      }
    }
  }
  .tabsBtnRow {
    position: absolute;
    bottom: ${rem(60)};
    right: 0;
    .btn {
      border-radius: ${rem(25)};
      border: solid ${rem(2)} ${color.graywhiteTwo};
      width: ${rem(144)};
      height: ${rem(50)};
      background-color: ${color.White};
      font-size: ${rem(14)};
      font-weight: 500;
      color: ${color.DarkBlack};
      text-transform: capitalize;
      margin-left: ${rem(20)};
      text-decoration: none;
    }
    .disabledBtn {
      background-color: ${color.graywhiteTwo};
      color: ${color.pinkishGrey};
    }
    .saveButton {
      color: ${color.White};
      background-image: linear-gradient(169deg, #a90b72 18%, #610140 82%, #5f013e 84%);
      border: transparent;
    }
  }
`;
