import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StackChartItem } from "components/Charts/Charts.types";
import { Severity } from "types";

type State = { point: StackChartItem; severity: Severity } | undefined;
type Payload = { key: string; value: State };

const initialState: Record<string, State> = {};

const fleetSelector = createSlice({
  name: "engineRoomFleetSelector",
  initialState,
  reducers: {
    setSelectedPoint(state, action: PayloadAction<Payload>) {
      const { key, value } = action.payload;
      state[key] = value;
    },
    clearFleetEngineRoomSelectedPoints(state) {
      // Return if already null
      const keys = Object.keys(state).length;
      if (!keys) return;

      return initialState;
    },
  },
});

export const { setSelectedPoint, clearFleetEngineRoomSelectedPoints } = fleetSelector.actions;
export default fleetSelector.reducer;
