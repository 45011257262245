import styled from "styled-components";
import { rem, color } from "../../../config/variable.styles";

export const GraphTooltipContainer = styled.div<{ left?: number }>`
  display: flex;
  flex-direction: column;
  .graphTooltipInner {
    position: relative;
    display: inline-block;
    z-index: 15;
    .tooltipContent {
      height: ${rem(101)};
      background-color: ${color.White};
      text-align: center;
      padding: ${rem(17)} ${rem(20)};
      transition: opacity 0.3s;
      filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.1));
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 15;
      &::after {
        content: "";
        position: absolute;
        width: ${rem(15)};
        height: ${rem(15)};
        bottom: ${rem(-7)};
        left: ${rem(20)};
        background: ${color.White};
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: -1;
        [data-popper-placement^="top-end"] & {
          left: inherit;
          right: ${rem(10)};
        }
        [data-popper-placement^="top-start"] & {
          left: ${rem(10)};
        }
        [data-popper-placement^="bottom-end"] & {
          left: ${(props) => rem(props.left ?? 224)};
          top: ${rem(-7)};
        }
        [data-popper-placement^="bottom-start"] & {
          left: ${rem(8)};
          top: ${rem(-7)};
        }
      }
      .tooltipContentList {
        width: 100%;
        ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          margin: 0 ${rem(-5)};
          li {
            :not(:last-child) {
              margin-right: ${rem(30)};
            }
            list-style: none;
            padding: 0 ${rem(5)};
            h3 {
              margin: 0 0 ${rem(15)};
              font-size: ${rem(14)};
              font-weight: 800;
              line-height: normal;
              letter-spacing: normal;
              color: ${color.DarkBlack};
            }
            .MuiChip-root {
              border-radius: ${rem(6)};
              height: 24px;
              .MuiChip-label {
                font-size: ${rem(14)};
                font-weight: 400;
                line-height: normal;
                letter-spacing: normal;
                color: ${color.DarkBlack};
              }
            }
          }
        }
      }
    }
  }
`;
