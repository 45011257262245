import { RefObject, useEffect } from "react";

const useOutsideHover = (ref: RefObject<HTMLDivElement>, onOutsideHover: () => void) => {
  useEffect(() => {
    function handleHoverOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideHover();
      }
    }
    document.addEventListener("mouseover", handleHoverOutside);
    return () => {
      document.removeEventListener("mouseover", handleHoverOutside);
    };
  }, [ref, onOutsideHover]);
};

export default useOutsideHover;
