import Chip from "components/Chip";
import { GraphTooltipContainer } from "./GraphTooltip.styles";

interface GraphTooltipProps {
  values: Array<{ title: string; value: string }>;
  left?: number;
}
const GraphTooltip = (props: GraphTooltipProps) => {
  const { values, left } = props;

  return (
    <GraphTooltipContainer left={left}>
      <div className="graphTooltipInner">
        <div className="tooltipContent">
          <div className="tooltipContentList">
            <ul>
              {values.map(({ title, value }) => (
                <li key={title}>
                  <h3>{title}</h3>
                  <Chip label={value ?? "---"} key={title} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </GraphTooltipContainer>
  );
};

export default GraphTooltip;
