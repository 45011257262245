import { rem, color, Transition, rgba } from "config/variable.styles";
import styled from "styled-components";

export const ProfileThumbStyle = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  &:hover {
    .edit-btn {
      bottom: ${rem(22)};
      opacity: 1;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .edit-btn {
    width: 100%;
    position: absolute;
    bottom: ${rem(11)};
    left: 0;
    opacity: 0;
    text-align: center;
    transition: ${Transition};
    input {
      display: none;
    }
    label {
      display: inline-flex;
    }
    .MuiButton-root {
      box-shadow: inherit;
      background-color: ${rgba(color.White, 0.75)};
      color: ${color.DarkBlack};
      font-size: ${rem(14)};
      font-weight: 500;
      border-radius: ${rem(16)};
      line-height: ${rem(17)};
      text-transform: capitalize;
      padding: ${rem(7)} ${rem(16)} ${rem(8)} ${rem(17)};
    }
  }
`;
