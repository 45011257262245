import { LineGraph } from "components/Charts";
import { format } from "date-fns";
import ChartCard from "components/ReportChartCard/ChartCard";
import { usePPESingleFleetData } from "queries";
import { useAppSelector } from "hooks";
import { ReportsProvider } from "../utils";
import { PPEReportChart } from "pages/Reports/Reports.styles";

const PPEExtendedGraph = () => {
  const { data, isFetching, isError, minBench: min, maxBench: max } = usePPESingleFleetData();
  const selectedPoint = useAppSelector((state) => state.ppereportsSelector.ppefleetPoint);

  if (!selectedPoint) return null;

  return (
    <ChartCard
      loading={isFetching}
      reverseHeading
      topRadiusNone
      height="auto"
      title="Vessel PPE Compliance Score"
      subTitle={`For ${format(selectedPoint.date, "MMM-yy")}, ${selectedPoint.name}`}
      tooltipContent={
        <div className="headingPopoverContent">
          <ul>
            <li>
              When a specific point is selected on the Fleet PPE Compliance graph above, this graph presents the PPE compliance scores for each vessel
              in the chosen fleet, for the selected month.
            </li>
          </ul>
        </div>
      }
    >
      <ReportsProvider>
        <PPEReportChart>
          <LineGraph
            xMin={0}
            xMax={100}
            benchmarkRange={{ min: min, max: max }}
            fleetName={selectedPoint.name ?? ""}
            error={isError}
            loading={isFetching}
            data={data}
            color={selectedPoint.color}
            renderLabels={(text) => text ?? ""}
            containerID="vessel-watch-graph"
            isPPE
          />
        </PPEReportChart>
      </ReportsProvider>
    </ChartCard>
  );
};

export { PPEExtendedGraph };
