import { NestedRouteConfig } from "components/NestedRoute";
import { lazyWithRetry } from "config/lazyrefresh";
import routes from "config/routes";
// import MarpolVessel from "./MarpolVessel";

const FleetReport = lazyWithRetry(() => import("./FleetReport"));
const BridgeWatchReport = lazyWithRetry(() => import("./BridgeWatchReport"));
const EngineRoomFleet = lazyWithRetry(() => import("./EngineRoomFleet"));
const EngineRoomVessel = lazyWithRetry(() => import("./EngineRoomVessel"));
const PPEReport = lazyWithRetry(() => import("./PPEReport"));
const PPEReportFleet = lazyWithRetry(() => import("./PPEReportFleet"));
const MarpolVessel = lazyWithRetry(() => import("./MarpolVessel"));

const getPath = (path: string) => `${routes.reports.path}/${path}`;

type Routes = "fleetReport" | "bridgeWatch" | "engineFleet" | "engineVessel" | "ppeReport" | "ppeReportFleet" | "marpolVessel";

export const reportsRoutes: NestedRouteConfig<Routes> = {
  fleetReport: {
    path: getPath("fleet-report"),
    component: FleetReport,
  },
  bridgeWatch: {
    path: getPath("bridgewatch"),
    component: BridgeWatchReport,
  },
  engineFleet: {
    path: getPath("engine-room-fleet"),
    component: EngineRoomFleet,
  },
  engineVessel: {
    path: getPath("engine-room-vessel"),
    component: EngineRoomVessel,
  },
  ppeReport: {
    path: getPath("ppe-report"),
    component: PPEReport,
  },
  ppeReportFleet: {
    path: getPath("ppe-report-fleet"),
    component: PPEReportFleet,
  },
  marpolVessel: {
    path: getPath("marpol-vessel"),
    component: MarpolVessel,
  },
};
