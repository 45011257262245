/*eslint max-lines: ["error", {"max": 450, "skipComments": true}]*/
import { TextField, Box } from "@material-ui/core";
// import Edit from "components/Icon/Edit";
import { useFormik } from "formik";
import * as yup from "yup";
import { EditIconBtn } from "./ProfileForm.styles";
import { ReactNode, useState } from "react";
import Skeleton from "components/Loaders/Skeleton";
import { toast } from "react-toastify";
import { SuccessToast } from "components/Toastbar";
import { SUCCESS_MESSASGE } from "constants/api/userProfile";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useCurrentUser, useInvalidateCurrentUser, useUpdateUserMutation } from "queries";
import { rem } from "config/variable.styles";
import Edit from "components/Icon/Edit";
import Button from "components/Button";
import { validation } from "utils";

interface FieldProps {
  label: string;
  children?: ReactNode;
  isLoading: boolean;
}
const Field = ({ label, children, isLoading }: FieldProps) => {
  return (
    <div className="informationRow">
      <label className="informationLabel">{label}</label>
      <div className="information">
        <Skeleton loading={isLoading}>{children ?? "-"}</Skeleton>
      </div>
    </div>
  );
};

interface NameFormProps {
  onCancel: () => void;
}

const schema = yup.object().shape({
  profile_name: validation.name(),
});

const NameForm = (props: NameFormProps) => {
  const { onCancel } = props;
  const { data: userProfile } = useCurrentUser();
  const invalidateCurrentUser = useInvalidateCurrentUser();
  const { mutate: updateUserProfile, isPending: isProfileUpdating } = useUpdateUserMutation({
    onSuccess: (data) => {
      onCancel();
      toast.success(<SuccessToast>{SUCCESS_MESSASGE}</SuccessToast>);
      invalidateCurrentUser(data);
    },
  });

  const { getFieldProps, values, handleSubmit, errors } = useFormik({
    initialValues: {
      profile_name: userProfile?.full_name || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (!userProfile?.id) return;
      updateUserProfile({ id: userProfile.id, full_name: values.profile_name.trim() });
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const hasErrors = Object.keys(errors).length > 0 || values?.profile_name?.trim() === (userProfile?.full_name ?? "");

  return (
    <form style={{ display: "flex", alignItems: "center", gap: rem(10) }} onSubmit={handleSubmit}>
      <TextField
        style={{ marginRight: rem(20) }}
        autoFocus
        {...getFieldProps("profile_name")}
        helperText={errors.profile_name}
        type="text"
        className="editProfileName"
        placeholder="Name"
      />
      <Button type="submit" variant="contained" disabled={hasErrors}>
        Save {isProfileUpdating ? <CircularProgress size={16} /> : null}
      </Button>
      <Button type="button" variant="outlined" onClick={onCancel} color="secondary">
        Cancel
      </Button>
    </form>
  );
};

/**
 * @deprecated
 */
const UserProfileTab = () => {
  const [editMode, setEditMode] = useState(false);
  const { data: userProfile, isLoading } = useCurrentUser();

  return (
    <Box mt={4} className="right-col-wrapper">
      <div className="informationList">
        <Field label="Email" isLoading={isLoading}>
          {userProfile?.email}
        </Field>
        <Field label="Name" isLoading={isLoading}>
          {editMode ? (
            <NameForm onCancel={() => setEditMode(false)} />
          ) : (
            <>
              {userProfile?.full_name ?? "-"}
              <EditIconBtn className="edit-control" onClick={() => setEditMode(true)}>
                <Edit />
              </EditIconBtn>
            </>
          )}
        </Field>
        <Field label="Role" isLoading={isLoading}>
          {userProfile?.role}
        </Field>
      </div>
    </Box>
  );
};

export default UserProfileTab;
