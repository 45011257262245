import { LegendsStyles } from "./Legends.styles";

interface LegendsProps {
  className?: string;
  leftBorder?: boolean;
  direction?: "row" | "column";
}
const Legends = (props: LegendsProps) => {
  const { className = "", leftBorder = false, direction = "row" } = props;
  const classNames = `${className} ${leftBorder ? "left-border" : ""} ${direction === "column" ? "vertical" : ""}`.trim();

  return (
    <LegendsStyles className={classNames}>
      <li>
        <span className="color-box bgFireEngineRed" /> Alert
      </li>
      <li>
        <span className="color-box bgPaleOrange" /> Attention
      </li>
      <li>
        <span className="color-box bgAquaMarine" /> Routine
      </li>
    </LegendsStyles>
  );
};

export { Legends };
