interface EditIconProps {
  size?: number | string;
  color?: string;
}
const Edit = (props: EditIconProps) => {
  const { size = "1em", color = "#000" } = props;

  return (
    <span className="icon icon-edit">
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <g fill={color} fillRule="nonzero">
          <path
            d="M23.2 24H8.8a.8.8 0 110-1.6h14.4a.8.8 0 110 
        1.6zM.8 24a.8.8 0 01-.779-.984l1.163-4.933a.79.79 0 01.213-.382L16.105
        2.993c.905-.906 2.488-.908 3.395 0L21.008 4.5c.453.454.703 1.056.703 1.697
        0 .642-.25 1.245-.703 1.697L6.299 22.603a.791.791 0 01-.381.213L.984 23.979a.776.776
        0 01-.184.02zm1.889-5.33l-.814 3.455 3.454-.814L19.876 6.764a.793.793 0 00.235-.566.791.791 0
        00-.235-.565l-1.508-1.51a.82.82 0 00-1.13 0L2.687 18.672zm3.064 3.393h.021-.021z"
          ></path>
          <path d="M17.215 11.356a.79.79 0 01-.565-.235l-3.772-3.772a.799.799 0 111.131-1.131l3.772 3.77a.8.8 0 01-.566 1.368z"></path>
        </g>
      </svg>
    </span>
  );
};

export default Edit;
