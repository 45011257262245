import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { ReportsV1Service, FleetSingleActivityCountResponse } from "@shipin/shipin-app-server-client";

import { queryKeys } from "config";
import { formatDate, toHours } from "utils";
import { useAppSelector } from "hooks";
import { StackChartItem } from "components/Charts/Charts.types";
import { Severity } from "types";

function useEngineRoomFleetActivities() {
  const { dateRange, fleet, compare_to } = useAppSelector((state) => state.engineFleetFilter);
  const fleets = [fleet?.id, ...compare_to];
  const fleetsIds = fleets?.filter(Boolean) as string[];
  const enabled = !!fleetsIds?.length && !!dateRange.start && !!dateRange.end;

  return useQuery({
    ...queryKeys.manualRefresh.engineRoomFleetActivities(dateRange.start, dateRange.end, fleets),
    queryFn: async () => {
      if (!fleetsIds?.length || !dateRange.start || !dateRange.end) return Promise.reject("Cannot fetch");

      return ReportsV1Service.postApiV1ReportsV1EngineRoomV1FleetActivities({
        fleets: fleetsIds,
        start_date: formatDate(dateRange.start, "yyyy-MM-dd"),
        end_date: formatDate(dateRange.end, "yyyy-MM-dd"),
      });
    },
    enabled,
    gcTime: 0,
    structuralSharing: false,
  });
}

const convert = (value: number | undefined) => (value === 0 ? value : Number(toHours(value)));

type SingleActivityOptions = {
  activity: string;
  severity: Severity;
  type: StackChartItem["type"];
  fleetID: string;
};
function useEngineRoomFleetSingleActivity({ activity, severity, type, fleetID }: SingleActivityOptions) {
  const { start, end } = useAppSelector((state) => state.engineFleetFilter.dateRange);

  const select = useCallback(
    (response: FleetSingleActivityCountResponse) => {
      if (!response?.fleets) return { chartData: [], benchmark: { min: 0, max: 0 } };

      const chartData = response.fleets?.[0]?.vessels?.map((v) => ({
        id: "1",
        coverage: type === "rate" ? v.monthly_rate : convert(v.mean_duration),
        vessel_id: v.vessel_id,
        vessel_name: v.name,
        fleet_id: response.fleets?.[0]?.fleet_id,
      }));

      const range =
        type === "rate"
          ? { min: response?.industry_benchmark?.month_rate_lower, max: response?.industry_benchmark?.month_rate_upper }
          : { min: convert(response?.industry_benchmark?.mean_duration_lower), max: convert(response?.industry_benchmark?.mean_duration_upper) };

      return { chartData, benchmark: range };
    },
    [type]
  );

  return useQuery({
    ...queryKeys.manualRefresh.engineRoomFleetSingleActivity(activity, severity, {
      dateRange: { start, end },
      fleetID,
    }),
    queryFn: () => {
      if (!fleetID) return Promise.reject("");
      const format = "yyyy-MM-dd";

      return ReportsV1Service.postApiV1ReportsV1EngineRoomV1FleetSingleActivity({
        //@ts-ignore
        severity,
        start_date: formatDate(start, format),
        end_date: formatDate(end, format),
        activity,
        fleets: [fleetID],
      });
    },
    enabled: !!fleetID,
    select,
    structuralSharing: false,
  });
}

export { useEngineRoomFleetActivities, useEngineRoomFleetSingleActivity };
