import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportData } from "components/Charts/Charts.types";

type State = {
  ppefleetPoint: ReportData[number] | null;
};
const initialState: State = {
  ppefleetPoint: null,
};
const ppereportsSelector = createSlice({
  name: "reportsSelector",
  initialState,
  reducers: {
    setPPEFleetPoint(state, action: PayloadAction<State["ppefleetPoint"]>) {
      state.ppefleetPoint = action.payload;
    },
    clearPPESelectedPoints(state) {
      // Return if already null
      if (!state.ppefleetPoint) return;

      return initialState;
    },
  },
});

export const { setPPEFleetPoint, clearPPESelectedPoints } = ppereportsSelector.actions;
export default ppereportsSelector.reducer;
