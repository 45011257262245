import { rem } from "config/variable.styles";
import styled from "styled-components";
import { colors } from "ui";

const FilterButtonRoot = styled.button`
  all: unset;
  cursor: pointer;
  font-size: ${rem(14)};
  line-height: ${rem(12)};
  display: flex;
  border-radius: ${rem(8)};
  border: none;
  outline: 1px solid ${colors.gray[300]};
  display: flex;
  gap: ${rem(8)};
  background-color: ${colors.background};
  padding: ${rem(8)} ${rem(12)};
  transition: border 0.2s ease-in-out;

  &.hasCount {
    gap: ${rem(4)};
  }

  &.active {
    outline: 2px solid ${colors.primary[500]};
    box-shadow: 2px 3px 2px 0px rgba(0, 0, 0, 0.25);
  }

  :disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .count {
    color: ${colors.primary[500]};
    font-weight: 700;
    letter-spacing: 0.4px;
  }
`;

export { FilterButtonRoot };
