import { useMemo } from "react";
import { ReportsV1Service } from "@shipin/shipin-app-server-client";
import { useQuery } from "@tanstack/react-query";
import { useAppSelector, useVessels } from "hooks";
import { queryKeys } from "config";
import { ReportData } from "components/Charts/Charts.types";
import { colorValues } from "components/Reports";
import { formatDate } from "utils";
import { addDays } from "date-fns";

function usePPEReport() {
  const { vessel, area, compare_to } = useAppSelector((state) => state.ppeReportFilter);
  const options = { vessel, area, compare_to };

  const payload = {
    vessel_id: vessel,
    area: area === "All" ? ["Deck", "Engine Room"] : [area],
    start_dttm: "2023-03-01",
    end_dttm: formatDate(new Date().toDateString(), "yyyy-MM-dd"),
    industry_benchmark: compare_to === "INDUSTRY_BENCHMARK",
    ...(compare_to !== "INDUSTRY_BENCHMARK" ? { compare_to } : {}),
  };

  const { data, isFetching, isError } = useQuery({
    ...queryKeys.manualRefresh.ppeReport(options),
    queryFn: () => ReportsV1Service.postApiV1ReportsV1PpeReport(payload),
    enabled: !!vessel,
    staleTime: 0,
    gcTime: 0,
    structuralSharing: false,
    select: (data) => data.data_points,
  });

  return { data, isFetching, isError };
}

function usePPEReportData() {
  const { data, isFetching, isError } = usePPEReport();
  const { data: vData, loading } = useVessels();

  const PPEData = useMemo(() => {
    const benchmarkData = (isUpper = false) => {
      return data
        ?.map(({ vessel_results, ...e }) => ({
          date: addDays(new Date(e.date), 1),
          ppe_kpi: isUpper
            ? e.benchmark_upper
              ? e.benchmark_upper * 100
              : e.benchmark_upper
            : e.benchmark_lower
            ? e.benchmark_lower * 100
            : e.benchmark_lower,
          vessel_id: isUpper ? "benchmark_upper" : "benchmark_lower",
          name: isUpper ? "Benchmark High" : "Benchmark Low",
          isBench: true,
          color: isUpper ? "#F27396" : "#C41244",
        }))
        .filter(Boolean) as ReportData;
    };

    const upperData = benchmarkData(true);
    const lowerData = benchmarkData();

    const groupedData: Record<string, { date: Date; vessel_id: string; ppe_kpi: number; name: string | undefined; color: string }[]> = {};

    data?.map((e) =>
      e.vessel_results.forEach((obj, i) => {
        const vesselId = obj.vessel_id;
        const vesselName = vData.find((e) => e.id === vesselId)?.name;
        if (groupedData[vesselId]) {
          groupedData[vesselId].push({
            ...obj,
            ppe_kpi: obj.ppe_kpi ? obj.ppe_kpi * 100 : obj.ppe_kpi,
            date: addDays(new Date(e.date), 1),
            name: vesselName,
            color: colorValues[i % colorValues.length],
          });
        } else {
          groupedData[vesselId] = [
            {
              ...obj,
              ppe_kpi: obj.ppe_kpi ? obj.ppe_kpi * 100 : obj.ppe_kpi,
              date: addDays(new Date(e.date), 1),
              name: vesselName,
              color: colorValues[i % colorValues.length],
            },
          ];
        }
      })
    );

    const result = Object.values(groupedData);
    const PPEData = [...result, lowerData, upperData];

    return PPEData;
  }, [data, vData]);

  return { data: PPEData, isFetching: isFetching || loading, isError };
}

export { usePPEReport, usePPEReportData };
