import React, { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { Grid } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import ChartCard from "components/ReportChartCard/ChartCard";
import { fetchReports } from "services/redux/reducers/Reports/ReportStats.slice";
import { useReportsContext } from "..";
import { formatDate, toMinutes } from "utils";
import StackChart from "components/Charts/Stackchart";
import { ActivityResponse } from "types/Reports.types";
import { StackChartItem } from "components/Charts/Charts.types";
import { setSelectedAlert } from "services/redux/reducers/Reports/ReportsSelector.slice";
import useFleets from "hooks/useFleets";
import { Error } from "components/Charts/Error";

export interface DataTypeProps {
  activities: Record<string, ActivityResponse>;
  id: string;
  color: string;
}
export interface TransformedDataTypeProps extends ActivityResponse {
  color: string;
  id: string;
}

interface TooltipContentProps {
  title: string;
}
function TooltipContent({ title }: TooltipContentProps) {
  return (
    <div className="headingPopoverContent">
      <ul>
        <li>
          Total number of {title} occurrences during the report period for the selected fleet, is shown on
          <br />
          the top-right.
        </li>
        <li>
          Normalized rate of {title} occurrences per vessel per month is shown on the left part -
          <br />
          average and range for all vessels in each fleet.
        </li>
        <li>
          Duration (in min) of {title} occurrences is shown on the right part - average and range for all
          <br />
          vessels in each fleet.
        </li>
        <li>The industry benchmark is mark as a reference.</li>
      </ul>
    </div>
  );
}

function AlertSummary() {
  const { status, data } = useAppSelector((state) => state.reportStats);
  const reportsContext = useReportsContext();
  const dispatch = useAppDispatch();
  const {
    fleet,
    compare_to,
    dateRange: { start, end },
  } = useAppSelector((state) => state.reportsFilter);
  const refresh = useAppSelector((state) => state.refreshTimer.manualRefresh);
  const transformDataRef = useRef<Record<string, string>>({ "1": "Benchmark" });
  const selectedBar = useRef<any>(null);
  const chartRef = useRef<am4charts.XYChart | null>(null);
  const { loading: isLoading } = useFleets();
  const loading = status === "loading" || isLoading;
  const error = status === "error";
  const values = useRef(reportsContext);

  useLayoutEffect(() => {
    values.current = reportsContext;
  }, [reportsContext]);

  useEffect(() => {
    if (!fleet?.id) return;

    dispatch(
      fetchReports({
        start_date: formatDate(start, "yyyy-MM-dd"),
        end_date: formatDate(end, "yyyy-MM-dd"),
        fleets: [fleet.id, ...compare_to],
      })
    );
  }, [dispatch, start, end, refresh, fleet?.id, compare_to]);

  const { countData, UnattendedRateData, UnattendedMonthData, BridgeRateData, BridgeMonthData, NonCompliantRateData, NonCompliantMonthData } =
    useMemo(() => {
      const activityData1: DataTypeProps[] | undefined = data?.fleets?.map((e) => ({
        activities: e.activities.reduce((a, v) => ({ ...a, [v.activity_key]: v }), {}),
        id: e.fleet_id,
        color: values.current.colors[e.fleet_id],
      }));

      const count = activityData1?.filter((i) => i.id === fleet?.id)[0];
      const countData = [
        count?.activities["Unattended Bridge"].count,
        count?.activities["Non-Compliant Watch Level"].count,
        count?.activities["Bridge Lighting at Night while Underway"].count,
      ];

      const datafn = (x: DataTypeProps[] | undefined, y: string) => {
        const data = x?.map((d: { activities: { [x: string]: any }; id: string; color: string }) => ({
          ...d.activities[y],
          id: d.id,
          color: d.color,
        }));
        return data;
      };

      const industryData: Record<string, Omit<ActivityResponse, "count" | "activity_key">> | undefined = data?.industry_benchmark?.reduce(
        (a, v) => ({ ...a, [v.activity_key]: v }),
        {}
      );

      const unattended_bridge = [{ ...industryData?.["Unattended Bridge"], color: "#eb2c62" }, datafn(activityData1, "Unattended Bridge")].flat();
      const nonComplaint = [
        { ...industryData?.["Non-Compliant Watch Level"], color: "#eb2c62" },
        datafn(activityData1, "Non-Compliant Watch Level"),
      ].flat();
      const bridgeLighting = [
        { ...industryData?.["Bridge Lighting at Night while Underway"], color: "#eb2c62" },
        datafn(activityData1, "Bridge Lighting at Night while Underway"),
      ].flat();

      const transformedData = (data: TransformedDataTypeProps[], countData: number | undefined, isRate?: boolean) => {
        if (!data) return;

        const chartData = data?.map((e, index: number) => {
          transformDataRef.current[`${index + 1}`] = values.current.fleetNames[e?.id] === undefined ? "Benchmark" : values.current.fleetNames[e?.id];
          const type: StackChartItem["type"] = isRate ? "rate" : "duration";
          const maxValue = isRate ? e?.month_rate_upper : Number(toMinutes(e?.mean_duration_upper));

          const transformed: StackChartItem[] = [
            {
              date: index + 1,
              fleet_id: e?.id,
              id: index,
              value: isRate ? e?.month_rate_lower : toMinutes(e?.mean_duration_lower) ?? 0,
              color: e?.color,
              fleet: values.current.fleetNames[e?.id],
              label: isRate ? "Rate of occurrences/Mo" : "Duration (min)",
              activity_key: e?.activity_key,
              type,
              maxValue,
            },
            {
              date: index + 1,
              fleet_id: e?.id,
              id: index,
              value: isRate ? e?.month_rate_avg : toMinutes(e?.mean_duration_avg) ?? 0,
              opacity: 1,
              color: e?.color,
              fleet: values.current.fleetNames[e?.id],
              label: isRate ? "Rate of occurrences/Mo" : "Duration (min)",
              activity_key: e?.activity_key,
              type,
              maxValue,
            },
            {
              date: index + 1,
              fleet_id: e?.id,
              id: index,
              value: isRate ? e?.month_rate_upper : toMinutes(e?.mean_duration_upper) ?? 0,
              color: e?.color,
              fleet: values.current.fleetNames[e?.id],
              label: isRate ? "Rate of occurrences/Mo" : "Duration (min)",
              activity_key: e?.activity_key,
              type,
              maxValue,
            },
          ];

          return transformed;
        });

        // We cannot determine if no data, just by checking the length of the array, because the array will always have 3 elements.
        // So we check if all the values in the array are 0. Attaching noData property to the array itself to prevent another loop.
        // during rendering.
        return {
          data: chartData,
          noData: chartData?.[0]?.every((e) => !e.value),
        };
      };

      return {
        UnattendedRateData: transformedData(unattended_bridge, countData?.[0], true),
        UnattendedMonthData: transformedData(unattended_bridge, countData?.[0]),
        BridgeRateData: transformedData(bridgeLighting, countData?.[1], true),
        BridgeMonthData: transformedData(bridgeLighting, countData?.[1]),
        NonCompliantRateData: transformedData(nonComplaint, countData?.[2], true),
        NonCompliantMonthData: transformedData(nonComplaint, countData?.[2]),
        countData: countData,
      };
    }, [data?.fleets, data?.industry_benchmark, fleet?.id]);

  const onSelect = (eventTarget: any, data: StackChartItem, chart: am4charts.XYChart) => {
    if (selectedBar.current && eventTarget === selectedBar.current) {
      selectedBar.current?.setAttribute("r", 6);
      selectedBar.current?.removeAttribute("fill-opacity");
      if (chartRef.current) {
        chartRef.current.background.opacity = 0;
      }
      selectedBar.current = null;
      chartRef.current = null;
      dispatch(setSelectedAlert(null));
      return;
    }
    selectedBar.current?.setAttribute("r", 6);
    selectedBar.current?.removeAttribute("fill-opacity");
    if (chartRef.current) {
      chartRef.current.background.opacity = 0;
    }
    selectedBar.current = eventTarget;
    chartRef.current = chart;
    dispatch(setSelectedAlert(data));
    selectedBar.current?.setAttribute("r", 10);
    selectedBar.current?.setAttribute("fill-opacity", 1);
    if (chartRef.current) {
      chartRef.current.background.fill = am4core.color(data.color);
      chartRef.current.background.opacity = 0.1;
    }
  };

  const renderChart = (
    rateDetails: typeof UnattendedRateData,
    monthDetails: typeof UnattendedMonthData,
    countData: number | undefined,
    containerID: string
  ) => {
    if (error || (rateDetails?.noData && monthDetails?.noData && !loading)) {
      return <Error variant={error ? "error" : "nodata"} height={230} size="xs" text="alerts" />;
    }

    return (
      <>
        <Grid className="item-card custom-left-chart-width" item xs={6}>
          <StackChart
            countData={countData}
            onSelect={onSelect}
            loading={loading}
            error={error}
            data={rateDetails?.data}
            containerID={`rate-${containerID}`}
          />
        </Grid>
        <Grid className="item-card custom-right-chart-width" item xs={6}>
          <StackChart
            countData={countData}
            onSelect={onSelect}
            showStats
            loading={loading}
            error={error}
            data={monthDetails?.data}
            containerID={`month-${containerID}`}
          />
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={0}>
      <Grid className="item-card" item xs={4}>
        <ChartCard
          isAlert
          rightBorder
          subTitle="Alert Summary"
          title="Unattended Bridge"
          tooltipContent={<TooltipContent title="Unattended Bridge" />}
        >
          {renderChart(UnattendedRateData, UnattendedMonthData, countData[0], "1")}
        </ChartCard>
      </Grid>
      <Grid className="item-card" item xs={4}>
        <ChartCard
          isAlert
          rightBorder
          title="Non-Compliant Watch Level"
          subTitle="Alert Summary"
          tooltipContent={<TooltipContent title="Non-Compliant Watch Level" />}
        >
          {renderChart(NonCompliantRateData, NonCompliantMonthData, countData[1], "2")}
        </ChartCard>
      </Grid>
      <Grid className="item-card" item xs={4}>
        <ChartCard
          isAlert
          rightBorder
          subTitle="Alert Summary"
          title="Bridge Lighting"
          tooltipContent={<TooltipContent title="Bridge Lighting at Night" />}
        >
          {renderChart(BridgeRateData, BridgeMonthData, countData[2], "3")}
        </ChartCard>
      </Grid>
    </Grid>
  );
}

export { AlertSummary };
