import { useLayoutEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { VirtualElement } from "@popperjs/core";

import { Error } from "./Error";
import { LineGraphData, LineGraphProps } from "./Charts.types";
import { ChartContainer } from "./Charts.styles";
import Skeleton from "components/Loaders/Skeleton";
import PopperPopover from "components/PopperPopover";
import GraphTooltip from "components/Reports/GraphTooltip";
import { useReportsContext } from "components/Reports";
am4core.options.autoDispose = true;

const LineGraph = (props: LineGraphProps) => {
  const {
    containerID,
    loading,
    error,
    data,
    color,
    fleetName,
    benchmarkRange: { min, max },
    renderLabels = (text) => (!!text ? text + "%" : ""),
    disableTooltip = false,
    xMin,
    xMax,
    isPPE = false,
  } = props;
  const { fleetNames } = useReportsContext();
  const [tooltip, setTooltip] = useState<{ referenceElement: Element | VirtualElement; data: LineGraphData[number] } | null>(null);
  const renderLabelFnRef = useRef(renderLabels);

  useLayoutEffect(() => {
    if (!data || !data.length || loading || error) return;

    const chart = am4core.create(containerID, am4charts.XYChart);
    chart.dateFormatter.dateFormat = "yyyy-MM-dd";
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    chart.maskBullets = false;

    chart.height = am4core.percent(100);

    chart.background.fill = am4core.color(color);
    chart.background.opacity = 0.1;
    chart.background.height = am4core.percent(60);
    chart.background.width = am4core.percent(60);

    const xAxes = chart.xAxes.push(new am4charts.ValueAxis());
    xAxes.renderer.minGridDistance = 50;
    xAxes.renderer.grid.template.location = 0.5;
    xAxes.startLocation = 0.5;
    xAxes.endLocation = 0.5;
    xAxes.renderer.grid.template.disabled = true;
    xAxes.renderer.labels.template.fontSize = 12;
    xAxes.renderer.line.strokeOpacity = 1;
    xAxes.renderer.line.stroke = am4core.color("#d6dce5");
    xAxes.renderer.ticks.template.disabled = false;
    xAxes.renderer.ticks.template.strokeOpacity = 1;
    xAxes.renderer.ticks.template.stroke = am4core.color("#d6dce5");
    xAxes.renderer.ticks.template.length = 12;
    xAxes.renderer.ticks.template.dy = -12;
    xAxes.renderer.labels.template.adapter.add("text", renderLabelFnRef.current);

    xAxes.min = xMin ?? 0;
    xAxes.max = xMax ?? 100;

    if (min != null) {
      const range = xAxes.axisRanges.create();
      range.value = min;
      range.grid.stroke = isPPE ? am4core.color("#C41244") : am4core.color("#eb2c62");
      range.grid.strokeWidth = 4;
      range.grid.strokeOpacity = isPPE ? 0.6 : 1;
      range.grid.strokeDasharray = "10 6";
    }

    if (max != null) {
      const range1 = xAxes.axisRanges.create();
      range1.value = max;
      range1.grid.stroke = isPPE ? am4core.color("#F27396") : am4core.color("#eb2c62");
      range1.grid.strokeWidth = 4;
      range1.grid.strokeOpacity = isPPE ? 0.6 : 1;
      range1.grid.strokeDasharray = "10 6";
    }

    const yAxes = chart.yAxes.push(new am4charts.ValueAxis());
    yAxes.renderer.grid.template.disabled = true;
    yAxes.renderer.labels.template.fontSize = 11;
    yAxes.renderer.line.strokeOpacity = 1;
    yAxes.renderer.line.stroke = am4core.color("#d6dce5");
    yAxes.renderer.labels.template.adapter.add("text", function (text) {
      return text === "1" && !!fleetName ? fleetName : "";
    });

    const series = chart.series.push(new am4charts.LineSeries());
    series.data = data;
    series.dataFields.valueX = "coverage";
    series.dataFields.valueY = "id";
    series.strokeWidth = 3;
    series.stroke = am4core.color(color);

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.height = 24;
    bullet.width = 24;
    bullet.circle.radius = 6;
    bullet.fill = am4core.color(color);
    bullet.events.on("over", (e: any) => {
      e.event.stopPropagation();
      setTooltip({ referenceElement: e.event.target, data: e.target.dataItem?.dataContext });
    });
    bullet.events.on("out", () => setTooltip(null));
    return () => {
      chart.dispose();
    };
  }, [data, loading, error, containerID, color, fleetName, min, max, xMin, xMax, isPPE]);

  if (error || (!data?.length && !loading)) {
    return (
      <ChartContainer>
        <Error variant={error ? "error" : "nodata"} size="small" height={125} />
      </ChartContainer>
    );
  }

  return (
    <ChartContainer>
      {!!tooltip && !disableTooltip && (
        <PopperPopover
          backgroundColor="transparent"
          className="reports-popover compliant-watch-popover"
          referenceElement={tooltip.referenceElement}
          placement="top-start"
          noborder
        >
          <GraphTooltip
            left={360}
            values={[
              { title: "Vessel", value: tooltip.data?.vessel_name },
              { title: "Fleet", value: fleetNames[tooltip.data.fleet_id] },
              { title: "KPI", value: tooltip.data.coverage?.toFixed(1) },
            ]}
          />
        </PopperPopover>
      )}
      <Skeleton loading={loading} height={125} width="100%">
        <div id={containerID} style={{ height: "125px" }} />
      </Skeleton>
    </ChartContainer>
  );
};

export { LineGraph };
