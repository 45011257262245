import { useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";

import { LineGraph } from "components/Charts";
import ChartCard from "components/ReportChartCard/ChartCard";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getFleetCoverageDay } from "services/redux/reducers/Reports/FleetCoverageDay.slice";
import { useReportsContext } from "../utils";

const VesselWatch = () => {
  const dispatch = useAppDispatch();
  const { fleetNames } = useReportsContext();
  const { data, status } = useAppSelector((state) => state.fleetCoverageDay);
  const { data: coverage, status: coverageStatus } = useAppSelector((state) => state.reports);
  const selectedPoint = useAppSelector((state) => state.reportsSelection.fleetCoverage);
  const refresh = useAppSelector((state) => state.refreshTimer.manualRefresh);
  const loading = status === "loading" || coverageStatus === "loading";
  const error = status === "error" || coverageStatus === "error";

  const chartData = useMemo(() => {
    if (!data || !data.fleets.length) return [];

    return data.fleets?.[0]?.vessels.map((item) => ({
      id: "1",
      coverage: item.coverage,
      vessel_id: item.vessel_id,
      vessel_name: item.name,
      fleet_id: data.fleets?.[0]?.fleet_id,
    }));
  }, [data]);

  useEffect(() => {
    if (!selectedPoint) return;

    const { date, fleet_id } = selectedPoint;
    dispatch(
      getFleetCoverageDay({
        date,
        fleets: [fleet_id],
      })
    );
  }, [dispatch, selectedPoint, refresh]);

  if (!selectedPoint) return null;
  const range = coverage?.data_points.find((e) => e.date === selectedPoint.date);

  return (
    <Grid className="item-card border-bottom-none" item xs={12} style={{ height: "auto" }}>
      <ChartCard
        loading={loading}
        reverseHeading
        topRadiusNone
        title="Vessel Watch Level Performance"
        subTitle={`For ${selectedPoint.date}, ${fleetNames[selectedPoint.fleet_id]}`}
        tooltipContent={
          <div className="headingPopoverContent">
            <ul>
              <li>
                When a specific point is selected in the Fleet Watch Level Performance graph above, this graph shows the watch <br /> level
                performance for each vessel in the selected fleet, for the selected date.
              </li>
            </ul>
          </div>
        }
      >
        <LineGraph
          xMin={0}
          xMax={100}
          benchmarkRange={{ min: range?.industry_benchmark_lower, max: range?.industry_benchmark_upper }}
          fleetName={fleetNames[selectedPoint.fleet_id]}
          error={error}
          loading={loading}
          data={chartData}
          color={selectedPoint.color}
          containerID="vessel-watch-graph"
        />
      </ChartCard>
    </Grid>
  );
};

export { VesselWatch };
