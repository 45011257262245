import { TileLayer as LeafletTileLayer } from "react-leaflet";

import { MapContainerStyle } from "./Map.styles";
import { MapProps, MapVariant, TileLayerProps as LTileLayerProps } from "./Map.types";
import { MAPTILER_KEY } from "config";

import "leaflet/dist/leaflet.css";
import "leaflet-rotatedmarker";

export const MAP_PROVIDERS: Record<MapVariant, string> = {
  default: `https://api.maptiler.com/maps/024030e2-df3d-4834-a7b9-3d4d9ca09dec/{z}/{x}/{y}.png?key=${MAPTILER_KEY}`,
  satellite: `https://api.maptiler.com/maps/c71e7f60-c07d-4a6c-b3bf-a2add22b4809/{z}/{x}/{y}.png?key=${MAPTILER_KEY}`,
};

type TileLayerProps = Omit<LTileLayerProps, "url"> & {
  variant?: MapVariant;
};
export const TileLayer = ({ variant = "default", ...rest }: TileLayerProps) => (
  <LeafletTileLayer url={MAP_PROVIDERS[variant]} tileSize={512} zoomOffset={-1} minZoom={3} {...rest} />
);

export const Map = (props: MapProps) => {
  const { children, disableDefaultTile = false, ...rest } = props;
  // noWrap prop prevents leaflet from repeating maps across pane
  // maxBounds specify limits of a single map in coordinates
  // Reference: https://leafletjs.com/reference.html#map-maxbounds
  // maxBoundsViscosity prevents user from dragging map beyond its size
  // Reference: https://leafletjs.com/reference.html#map-maxboundsviscosity
  return (
    <MapContainerStyle
      maxBounds={[
        [-90, -Infinity],
        [90, Infinity],
      ]}
      center={[0, 0]}
      zoom={3}
      zoomControl={false}
      {...rest}
    >
      {!disableDefaultTile && <TileLayer />}
      {children}
    </MapContainerStyle>
  );
};
