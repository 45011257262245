import {
  // TextField, Select, MenuItem,
  Grid,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import {
  GridStyle,
  SaveButton,
  // EditIconBtn,
} from "./ProfileForm.styles";
import ProfileThumb from "components/ProfileThumb/ProfileThumb";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getSignedUrl } from "services/redux/reducers/User/signedUrl.slice";
import Skeleton from "components/Loaders/Skeleton";
import { toast } from "react-toastify";
import { SuccessToast } from "components/Toastbar";
import { SUCCESS_MESSASGE } from "constants/api/userProfile";
import { useCurrentUser, useInvalidateCurrentUser, useUpdateUserMutation } from "queries";

const ProfileLeftForm = () => {
  const [profileEditMode, setProfileEditMode] = useState(false);
  const { status: signUrlStatus } = useAppSelector(({ signedUrl }) => signedUrl);
  const isLoadingUpdate = profileEditMode && signUrlStatus === "loading";
  const dispatch = useAppDispatch();

  const invalidateCurrentUser = useInvalidateCurrentUser();
  const { data: userProfile, isLoading } = useCurrentUser();
  const { mutate: updateUserProfile } = useUpdateUserMutation({
    onSuccess: (data) => {
      toast.success(<SuccessToast>{SUCCESS_MESSASGE}</SuccessToast>);
      setProfileEditMode(false);
      invalidateCurrentUser(data);
    },
  });

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      image_url: userProfile?.image_url || "",
    },
    validationSchema: Yup.object().shape({
      image_url: Yup.string(),
    }),
    onSubmit: (formValues) => {
      if (!userProfile?.id) return;

      dispatch(getSignedUrl({ userId: userProfile?.id, imageURl: formValues?.image_url }))
        .then((res: any) => {
          if (!!res?.payload?.get_url && typeof res?.payload?.get_url === "string") {
            updateUserProfile({
              image_url: res?.payload?.get_url,
              id: userProfile?.id,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(values.image_url);
    };
  }, [values.image_url]);

  return (
    <form onSubmit={handleSubmit}>
      <ProfileThumb
        loading={isLoading || isLoadingUpdate}
        setFieldValue={setFieldValue}
        image_url={values.image_url}
        setProfileEditMode={setProfileEditMode}
        profileEditMode={profileEditMode}
      />
      <GridStyle style={{ marginLeft: "-10px", marginTop: "10px" }} container>
        <Grid item xs={12}>
          <div className="form-group profile-details-wrap">
            <FormControl>
              <InputLabel id="profile-last-login">Last Login</InputLabel>
              <div className="form-view-control">
                <Skeleton loading={isLoading} animation="wave" height={20} width={100}>
                  {userProfile?.last_login ? format(new Date(userProfile.last_login), "MMM dd, yyyy hh:mmaaa") : "-"}
                </Skeleton>
              </div>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12}>
          {profileEditMode && (
            <div className="form-group">
              <SaveButton variant="contained" type="submit">
                Save {isLoadingUpdate ? <CircularProgress size={16} /> : ""} {/* <CircularProgress size={16} />  */}
              </SaveButton>
            </div>
          )}
        </Grid>
      </GridStyle>
    </form>
  );
};

export default ProfileLeftForm;
