import { useAppSelector } from "hooks/reduxHooks";
import { useReportsContext } from "../utils";
import { Legends } from "components/Legends/Legends";

const EngineRoomLegendsDisplay = () => {
  const selectedVessel = useAppSelector((state) => state.engineRoomFilter.vessel);
  const compare_to = useAppSelector((state) => state.engineRoomFilter.compare_to);
  const { fleetNames } = useReportsContext();

  const selectedFleet = compare_to === "INDUSTRY_BENCHMARK" ? "Industry Benchmark" : fleetNames[compare_to];

  return (
    <div className="customLegendRow">
      <ul className="chartCustomLegend">
        <li>
          <span className="color-box bgPurple" />
          {selectedFleet}
        </li>
        {selectedVessel?.name && (
          <li>
            <span className="color-box bgDarkBlue" />
            {selectedVessel?.name}
          </li>
        )}
      </ul>
      <Legends leftBorder />
    </div>
  );
};

export { EngineRoomLegendsDisplay };
