import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { FleetReportFilter } from "types/FilterBar.types";

interface PPEReportFleetTypes extends Omit<FleetReportFilter, "dateRange"> {
  area: string;
}
const initialState: PPEReportFleetTypes = {
  fleet: { name: "", id: "" },
  area: "All",
  compare_to: [],
};

const ppeReportFleet = createSlice({
  name: "ppeReportFilter",
  initialState,
  reducers: {
    setPPEFleetFilter(state, action: PayloadAction<typeof initialState.fleet>) {
      state.fleet = action.payload;
    },
    setPPEFleetArea(state, action: PayloadAction<typeof initialState.area>) {
      state.area = action.payload;
    },
    setPPEFleetCompareTo(state, action: PayloadAction<typeof initialState.compare_to>) {
      // If current and next state are same, don't update anything in slice.
      const currentState = [...state.compare_to].sort();
      const nextState = [...action.payload].sort();
      if (isEqual(currentState, nextState)) return;
      state.compare_to = action.payload;
    },
  },
});

export const { setPPEFleetFilter, setPPEFleetCompareTo, setPPEFleetArea } = ppeReportFleet.actions;
export default ppeReportFleet.reducer;
