import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { OpenAPI } from "@shipin/shipin-app-server-client";

import { toast } from "react-toastify";
import { ERROR_MESSAGE } from "../constants/api/common";
import { ErrorToast } from "../components/Toastbar";
import routes from "./routes";
import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";
import { MethodInfo, NextUnaryFn, RpcError, RpcOptions, UnaryCall } from "@protobuf-ts/runtime-rpc";
import { FieldInfo, LongType, RepeatType } from "@protobuf-ts/runtime";
import { isEdge } from "utils";

declare module "axios" {
  export interface AxiosRequestConfig {
    disableErrorToast?: boolean;
  }
}

const BASE_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL;
const AUTH_SERVICE = window.__RUNTIME_CONFIG__.AUTH_SERVICE;

/**
 * Current Base URL has /api/v1 at the end
 * which is not required for generated client.
 */
OpenAPI.BASE = BASE_URL.split("/api/v1")[0];
// set withCredentials on the generated api.
OpenAPI.WITH_CREDENTIALS = true;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  // This allows axios sending token via cookie
  withCredentials: true,
});

interface RetryConfig extends AxiosRequestConfig {
  attempt?: number;
}

const redirect = (redirectURL: string) => {
  localStorage.clear();
  const url = new URL(window.location.origin + routes.welcome.path);
  url.searchParams.set("redirect_to", redirectURL);
  window.location.replace(url.href);
};

const requestInterceptors = [
  function (config: AxiosRequestConfig) {
    return config;
  },
  function (error: any) {
    toast.error(<ErrorToast>{error.message || ERROR_MESSAGE}</ErrorToast>);
    return Promise.reject(error);
  },
] as const;

let loginFuture: Promise<void> | null;

const responseInterceptors = [
  function (response: AxiosResponse) {
    return response;
  },
  function (error: AxiosError) {
    if (isCancel(error)) return;
    if (error.response?.status === 401 || error.response?.status === 422) {
      let errorCode = error.response?.data?.code;

      if (errorCode === "denied" || errorCode === "disabled") {
        window.location.replace("/error/" + errorCode);
        return Promise.reject(error);
      }

      if (loginFuture == null) {
        loginFuture = new Promise((resolve, reject) => {
          return axios
            .get(AUTH_SERVICE + "/login.json", {
              params: {
                next_url: window.location.href,
              },
              withCredentials: true,
            })
            .then((response) => {
              if (response.data.action === "retry") {
                resolve();
                // Cleanup login "lock"
                setTimeout(() => {
                  loginFuture = null;
                }, 10000);
              } else if (response.data.action === "redirect") {
                redirect(response.data.url);
                reject();
              } else {
                reject();
                // Cleanup login "lock"
                setTimeout(() => {
                  loginFuture = null;
                }, 10000);
              }
            }, reject);
        });
      }

      return loginFuture.then(
        () => {
          const newConfig: RetryConfig = error.config;

          if (newConfig.attempt === undefined) {
            newConfig.attempt = 1;
          } else {
            return Promise.reject(error);
          }

          return axios(error.config);
        },
        () => Promise.reject(error)
      );
    }

    if (error.response?.status === 403) {
      toast.error(<ErrorToast>You have no permission to perform this action.</ErrorToast>);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
] as const;

// Configuring interceptores for global axios.
// It will be used for API calls made using generated client.
axios.interceptors.request.use(...requestInterceptors);
axios.interceptors.response.use(...responseInterceptors);

// Configuring interceptores for axios instance.
// We will remove this once we migrate entire app to use client.
// Until then, this will be used.
axiosInstance.interceptors.request.use(...requestInterceptors);
axiosInstance.interceptors.response.use(...responseInterceptors);

export const isCancel = axios.isCancel;
export const cancelToken = axios.CancelToken;
export default axiosInstance;

const MAX_OBJECT_COUNT = 100;

class ObjectCount {
  count: number = 0;
}

function toStandard(objValue: any, fields: readonly FieldInfo[]) {
  const objectCount = new ObjectCount();

  const result = toStandardInner(objectCount, objValue, fields);

  if(objectCount.count > MAX_OBJECT_COUNT){
    result["$truncated"] = true;
  }

  return result;
}

function toStandardInner(objectCount: ObjectCount, objValue: any, fields: readonly FieldInfo[]) {
  objectCount.count += 1;

  const result: any = {};

  fields.forEach((field) => {
    const forValue = (value: any) => {
      if (field.kind === "message") {
        if (value === undefined) {
          return undefined;
        }

        // Handle well known type timestamp.
        if (field.T().typeName === "google.protobuf.Timestamp") {
          const ts = new Date(Number(value.seconds) * 1000 + Math.floor(value.nanos / 1000000));
          // Print the human-readable timestamp, as well as the unix-timestamp for easier copy-paste
          return ts.toISOString() + " (" + value.seconds.toString() + ", " + value.nanos.toString() + ")";
        }

        return toStandardInner(objectCount, value, field.T().fields);
      } else if (field.kind === "enum") {
        return field.T()[1][value];
      } else if (field.kind === "scalar") {
        if (field.L === LongType.BIGINT) {
          return Number(value);
        } else {
          return value;
        }
      }
    };

    if (field.repeat === RepeatType.NO) {
      result[field.jsonName] = forValue(objValue[field.jsonName]);
    } else {
      if(objectCount.count < MAX_OBJECT_COUNT) {
        result[field.jsonName] = objValue[field.jsonName].map(forValue);
      } else {
        result[field.jsonName] = {"$type":"repeated", "$truncated": true};
      }
    }
  });

  return result;
}

export const transport = new GrpcWebFetchTransport({
  baseUrl: window.__RUNTIME_CONFIG__.PROTO_URL,
  fetchInit: { credentials: "include" },
  interceptors: [
    {
      // Debug interceptor, support reading token from url query
      interceptUnary(next: NextUnaryFn, method: MethodInfo, input: object, options: RpcOptions): UnaryCall {
        const setToken = /\?token=(.*)$/.exec(window.location.href);

        if (setToken != null) {
          window.sessionStorage.setItem("token", setToken[1]);
        }

        const token = window.sessionStorage.getItem("token");
        if (token != null) {
          if (options.meta === undefined) {
            options.meta = {
              authorization: "Bearer " + token,
            };
          } else {
            options.meta = {
              ...options.meta,
              authorization: "Bearer " + token,
            };
          }
        }

        return next(method, input, options);
      },
    },
    {
      interceptUnary(next, method, input, options) {
        const call = next(method, input, options);
        const methodFullPath = `${options.baseUrl}/${method.service.typeName}/${method.name}`;
        const methodType = "unary";

        call.then(
          (finishedUnaryCall) => {
            window.postMessage(
              {
                type: "__GRPCWEB_DEVTOOLS__",
                method: methodFullPath,
                methodType,
                request: toStandard(finishedUnaryCall.request, method.I.fields),
                response: toStandard(finishedUnaryCall.response, method.O.fields),
              },
              "*"
            );

            return finishedUnaryCall;
          },
          (error: RpcError) => {
            window.postMessage(
              {
                type: "__GRPCWEB_DEVTOOLS__",
                method: methodFullPath,
                methodType,
                request: toStandard(call.request, method.I.fields),
                error: {
                  ...error,
                  message: error.message,
                },
              },
              "*"
            );
            // Auto logout for shore
            // codes like UNATHENTICATED are not added in a variable, because
            // It crashes the App for some reason.
            if (error.code === "UNAUTHENTICATED" && !isEdge) {
              localStorage.clear();
              return axios
                .get(AUTH_SERVICE + "/login.json", {
                  params: {
                    next_url: window.location.href,
                  },
                  withCredentials: true,
                })
                .then((response) => {
                  if (response.data.action === "redirect") {
                    redirect(response.data.url);
                  }
                })
                .catch(console.error);
            }
          }
        );

        return call;
      },
      interceptServerStreaming(next, method, input, options) {
        const call = next(method, input, options);
        const methodFullPath = `${options.baseUrl}/${method.service.typeName}/${method.name}`;
        const methodType = "server_streaming";

        window.postMessage({
          type: "__GRPCWEB_DEVTOOLS__",
          method: methodFullPath,
          methodType,
          request: toStandard(call.request, method.I.fields),
        });

        call.responses.onMessage((message) => {
          window.postMessage(
            {
              type: "__GRPCWEB_DEVTOOLS__",
              method: methodFullPath,
              methodType,
              response: toStandard(message, method.O.fields),
            },
            "*"
          );
        });

        call.responses.onError((error) => {
          window.postMessage(
            {
              type: "__GRPCWEB_DEVTOOLS__",
              method: methodFullPath,
              methodType,
              error: {
                ...error,
                message: error.message,
              },
            },
            "*"
          );
        });

        call.responses.onComplete(() => {
          window.postMessage(
            {
              type: "__GRPCWEB_DEVTOOLS__",
              method: methodFullPath,
              methodType,
              response: "EOF",
            },
            "*"
          );
        });

        return call;
      },
    },
  ],
});
