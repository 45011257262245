import { useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";

import { formatDate, toMinutes } from "utils";
import { LineGraph } from "components/Charts";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getFleetBridgeSingleActivity } from "services/redux/reducers/Reports/BridgeSingleActivity.slice";
import ChartCard from "components/ReportChartCard/ChartCard";

const convert = (value: number | undefined) => (value === 0 ? value : Number(toMinutes(value)));

const graphLabel: Record<string, string> = {
  "Unattended Bridge": "Unattended Bridge",
  "Non-Compliant Watch Level": "Non-Compliant Watch Level",
  "Bridge Lighting at Night while Underway": "Bridge Lighting",
};

const UnattendedBridge = () => {
  const dispatch = useAppDispatch();
  const { start, end } = useAppSelector((state) => state.reportsFilter.dateRange);
  const { data, status } = useAppSelector((state) => state.bridgeSingleActivity);
  const selectedPoint = useAppSelector((state) => state.reportsSelection.alertSummary);
  const refresh = useAppSelector((state) => state.refreshTimer.manualRefresh);
  const loading = status === "loading";
  const error = status === "error";

  const chartData = useMemo(() => {
    if (!data?.fleets || !selectedPoint) return [];
    const { type } = selectedPoint;

    return data.fleets?.[0]?.vessels?.map((v) => ({
      id: "1",
      coverage: type === "rate" ? v.monthly_rate : convert(v.mean_duration),
      vessel_id: v.vessel_id,
      vessel_name: v.name,
      fleet_id: data.fleets?.[0]?.fleet_id,
    }));
  }, [data, selectedPoint]);

  useEffect(() => {
    if (!selectedPoint) return;

    dispatch(
      getFleetBridgeSingleActivity({
        activity: selectedPoint.activity_key,
        fleets: [selectedPoint.fleet_id],
        start_date: formatDate(start, "yyyy-MM-dd"),
        end_date: formatDate(end, "yyyy-MM-dd"),
      })
    );
  }, [start, end, dispatch, selectedPoint, refresh]);

  if (!selectedPoint) return null;

  const range =
    selectedPoint.type === "rate"
      ? { min: data?.industry_benchmark.month_rate_lower, max: data?.industry_benchmark.month_rate_upper }
      : { min: convert(data?.industry_benchmark.mean_duration_lower), max: convert(data?.industry_benchmark.mean_duration_upper) };
  const xMax = Math.max(range.max ?? 10, selectedPoint.maxValue ?? 10, ...(chartData ?? []).map((e) => e.coverage)) + 10;

  return (
    <Grid className="item-card border-bottom" item xs={12} style={{ height: "auto" }}>
      <ChartCard
        loading={loading}
        title={graphLabel[selectedPoint?.activity_key]}
        reverseHeading
        subTitle={`${selectedPoint?.label}, ${selectedPoint?.fleet}`}
        tooltipContent={
          <div className="headingPopoverContent">
            <ul>
              <li>
                When a specific point is selected in the Alert summary graph above, this graph shows the data for each vessel in <br /> the selected
                fleet.
              </li>
            </ul>
          </div>
        }
      >
        <LineGraph
          xMin={0}
          xMax={xMax}
          containerID="unattended-graph"
          benchmarkRange={range}
          color={selectedPoint.color}
          data={chartData}
          fleetName={selectedPoint.fleet}
          loading={loading}
          error={error}
          renderLabels={(text) => (!!text ? text : "")}
        />
      </ChartCard>
    </Grid>
  );
};

export { UnattendedBridge };
