import styled from "styled-components";
import { rem, color } from "config/variable.styles";

export const ChartContainer = styled.div`
  .chart-info-row {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: -${rem(56)};
    min-height: ${rem(56)};
    .chart-info-item {
      margin-left: ${rem(20)};
      &.text-right {
        text-align: right;
      }
      h3 {
        font-size: ${rem(36)};
        line-height: ${rem(40)};
        font-weight: 800;
        color: ${color.DarkBlack};
        margin: 0;
      }
      h4 {
        font-size: ${rem(18)};
        line-height: ${rem(20)};
        font-weight: 800;
        color: ${color.DarkBlack};
        margin: 0;
      }
      .chart-info-text {
        font-size: ${rem(11)};
        line-height: ${rem(14)};
        color: ${color.DarkBlack};
        margin: 0;
      }
      .MuiChip-root {
        border-radius: ${rem(2)};
        padding: ${rem(2)} ${rem(7)};
        height: ${rem(15)};
        min-width: ${rem(82)};
        &.MuiChip-colorPrimary {
          background: ${color.purplishBlue};
          color: ${color.White};
        }
        &.MuiChip-colorSecondary {
          background: ${color.aquaMarine};
          color: ${color.DarkBlack};
        }
        .MuiChip-label {
          padding: 0;
          font-size: ${rem(9)};
        }
      }
    }
  }
`;

export const ErrorContainer = styled.div<{ small?: boolean; height?: number }>`
  min-height: ${({ height }) => (height ? rem(height) : rem(171))};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${rem(0)};
  .icon {
    font-size: ${({ small }) => (small ? "30px" : "50px")};
  }
  .text {
    display: block;
    font-size: 20px;
    margin: 0;
    padding-left: 10px;
  }
`;

export const TimelineBase = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  padding-left: ${rem(60)};
  position: relative;
`;

export const TimelineRoot = styled.div`
  width: calc(100% - ${rem(64)});
  max-width: calc(100% - ${rem(64)});
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const GenericTimelineRoot = styled.div`
  width: 100%;
  font-size: ${rem(14)};
  display: flex;
  flex-direction: column;
  .h-63 {
    height: ${rem(63)};
  }
  .w-100 {
    width: 100%;
  }
  .label {
    width: max-content;
    margin-bottom: ${rem(-12)};
  }
  .chart {
    height: ${rem(30)};
    overflow: hidden;
  }
`;
