import React from "react";
import { InfoSummaryWidgetBody, InfoSummaryWidgetContainer } from "./InfoSummaryWidget.styles";

interface Events {
  count: string | number;
  bgColor?: string;
  label?: string;
  hidden?: boolean;
  className?: string;
}
interface PropType {
  title?: string;
  eventsData?: Events[];
}
function InfoSummaryWidget(props: PropType) {
  const { eventsData } = props;

  return (
    <InfoSummaryWidgetContainer>
      <InfoSummaryWidgetBody>
        {eventsData && (
          <div className="summaryWidgetDeta">
            <ul>
              {eventsData.map((data, index) => {
                const { bgColor, count, label, hidden = false, className = "" } = data;
                return !hidden ? (
                  <li key={index} className={className}>
                    <h2 className="summaryWidgetDetaTitle">{typeof count === "number" ? Math.round(count) : count}%</h2>
                    <span className="summaryWidgetBg" style={{ backgroundColor: bgColor }}></span>
                    <label className="summaryWidgetLabel">{label}</label>
                  </li>
                ) : null;
              })}
            </ul>
          </div>
        )}
      </InfoSummaryWidgetBody>
    </InfoSummaryWidgetContainer>
  );
}

export default InfoSummaryWidget;
