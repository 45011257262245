import React, { CSSProperties, ReactNode } from "react";

import Skeleton from "components/Loaders/Skeleton";
import { ChartCardStyles } from "./ChartCard.styles";

interface ChartCardProps {
  title?: string;
  children?: ReactNode;
  loading?: boolean;
  tooltipContent?: ReactNode;
  /** isAlert is used to indicate use display flex in chart container. */
  isAlert?: boolean;
  height?: number | string;
  chartCardStyle?: CSSProperties;
  topLabel?: ReactNode;
  labelStyle?: CSSProperties;
  /** To indicate that the title is for generic report and to lower case it's 'for' in it*/
  isGenericTitle?: boolean;
}
const GenericChartCard = (props: ChartCardProps) => {
  const { children, title, labelStyle, loading, isAlert = false, topLabel, height, chartCardStyle, isGenericTitle = false } = props;

  return (
    <ChartCardStyles height={height}>
      <div className={`heading headingTitle ${isGenericTitle ? "genericTitle" : ""}`}>
        <Skeleton loading={loading} animation="wave" height={20} width="100px">
          <h2 className="blue">{title}</h2>
        </Skeleton>
      </div>
      {topLabel && (
        <div style={labelStyle} className="top-label">
          {topLabel}
        </div>
      )}
      <div style={chartCardStyle} className={isAlert ? "ChartCardContent AlertChartContent" : "ChartCardContent"}>
        {children}
      </div>
    </ChartCardStyles>
  );
};

export default GenericChartCard;
