import styled from "styled-components";
import { rem, color, xl } from "../../config/variable.styles";

export const InfoSummaryBarRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InfoSummaryBarColContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${rem(0)};
  background: ${color.White};
  margin: 0 ${rem(0)};
  ${xl(`
      padding: ${rem(0)};
  `)}
  /* &:hover {
    background: #ddd;
  } */
`;
