import { FormCol, ProfileFormStyle } from "components/ProfileForm/ProfileForm.styles";
import ProfileLeftForm from "components/ProfileForm/ProfileLeftForm";
import UserProfile from "components/ProfileForm/UserProfileTab";

const UserInformation = () => {
  return (
    <ProfileFormStyle>
      <div className="left-col">
        <FormCol>
          <ProfileLeftForm />
        </FormCol>
      </div>
      <div className="right-col">
        <UserProfile />
      </div>
    </ProfileFormStyle>
  );
};

export default UserInformation;
