import { useMemo } from "react";
import { FleetPPEReportRequest, ReportsV1Service } from "@shipin/shipin-app-server-client";
import { useQuery } from "@tanstack/react-query";
import { useAppSelector, useVessels } from "hooks";
import { queryKeys } from "config";
import { ReportData } from "components/Charts/Charts.types";
import { colorValues } from "components/Reports";
import { formatDate } from "utils";
import useFleets from "hooks/useFleets";
import { addDays, isSameDay } from "date-fns";

function usePPEFleet() {
  const { fleet, area, compare_to } = useAppSelector((state) => state.ppeReportFleet);
  const fleets = [fleet?.id, ...compare_to];
  const fleetsIds = fleets?.filter(Boolean) as string[];
  const options = { fleetsIds, area };

  const payload: FleetPPEReportRequest = {
    fleet_ids: fleetsIds,
    area: area === "All" ? ["Deck", "Engine Room"] : [area],
    start_dttm: "2023-03-01",
    end_dttm: formatDate(new Date().toDateString(), "yyyy-MM-dd"),
  };

  const { data, isFetching, isError } = useQuery({
    ...queryKeys.manualRefresh.ppeReportFleet(options),
    queryFn: () => ReportsV1Service.postApiV1ReportsV1PpeReportFleet(payload),
    enabled: !!fleetsIds.length,
    staleTime: 0,
    gcTime: 0,
    structuralSharing: false,
  });

  return { data, isFetching, isError };
}

function usePPEFleetData() {
  const { data, isFetching, isError } = usePPEFleet();
  const { data: fleetData, loading } = useFleets();

  const PPEData = useMemo(() => {
    const benchmarkData = (isUpper = false) => {
      return data?.data_points
        ?.map(({ fleet_results, ...e }) => ({
          date: addDays(new Date(e.date), 1),
          ppe_kpi: isUpper
            ? e.benchmark_upper
              ? e.benchmark_upper * 100
              : e.benchmark_upper
            : e.benchmark_lower
            ? e.benchmark_lower * 100
            : e.benchmark_lower,
          vessel_id: isUpper ? "benchmark_upper" : "benchmark_lower",
          name: isUpper ? "Benchmark High" : "Benchmark Low",
          isBench: true,
          color: isUpper ? "#F27396" : "#C41244",
        }))
        .filter(Boolean) as ReportData;
    };

    const upperData = benchmarkData(true);
    const lowerData = benchmarkData();

    const groupedData: Record<string, { date: Date; vessel_id: string; ppe_kpi: number; name: string | undefined; color: string }[]> = {};

    data?.data_points?.map((e) =>
      e.fleet_results.forEach((obj, i) => {
        const vesselId = obj.fleet_id;
        const vesselName = fleetData?.fleets?.find((e) => e.id === vesselId)?.name;
        if (groupedData[vesselId]) {
          groupedData[vesselId].push({
            vessel_id: obj.fleet_id,
            ppe_kpi: obj.ppe_kpi ? obj.ppe_kpi * 100 : obj.ppe_kpi,
            date: addDays(new Date(e.date), 1),
            name: vesselName,
            color: colorValues[i % colorValues.length],
          });
        } else {
          groupedData[vesselId] = [
            {
              vessel_id: obj.fleet_id,
              ppe_kpi: obj.ppe_kpi ? obj.ppe_kpi * 100 : obj.ppe_kpi,
              date: addDays(new Date(e.date), 1),
              name: vesselName,
              color: colorValues[i % colorValues.length],
            },
          ];
        }
      })
    );

    const result = Object.values(groupedData);
    const PPEData = [...result, lowerData, upperData];

    return { PPEData, lowerData, upperData };
  }, [data, fleetData]);

  return { data: PPEData.PPEData, isFetching: isFetching || loading, isError, lowerData: PPEData.lowerData, upperData: PPEData.upperData };
}

function usePPESingleFleet() {
  const { area } = useAppSelector((state) => state.ppeReportFleet);
  const { ppefleetPoint } = useAppSelector((state) => state.ppereportsSelector);

  const options = { fleetId: ppefleetPoint?.vessel_id, area, date: new Date(ppefleetPoint?.date ?? "") };

  const { data, isFetching, isError } = useQuery({
    ...queryKeys.manualRefresh.ppeFleetVessels(options),
    queryFn: async () => {
      if (!ppefleetPoint) return Promise.reject(new Error());
      return await ReportsV1Service.postApiV1ReportsV1PpeReportFleetVessels({
        fleet_id: ppefleetPoint.vessel_id,
        dttm: formatDate(ppefleetPoint.date.toString(), "yyyy-MM-dd"),
        area: area === "All" ? ["Deck", "Engine Room"] : [area],
      });
    },
    enabled: !!ppefleetPoint,
    staleTime: 0,
    gcTime: 0,
    structuralSharing: false,
  });

  return { data, isFetching, isError };
}

function usePPESingleFleetData() {
  const { data, isFetching, isError } = usePPESingleFleet();
  const { data: vData, loading } = useVessels();
  const { lowerData, upperData } = usePPEFleetData();

  const { chartData, minBench, maxBench } = useMemo(() => {
    if (!data) return {};

    const minBench = lowerData?.find((e) => isSameDay(e.date, new Date(data.dttm)))?.ppe_kpi;
    const maxBench = upperData?.find((e) => isSameDay(e.date, new Date(data.dttm)))?.ppe_kpi;

    const chartData = data.data_points.map((item) => ({
      id: "1",
      coverage: item.ppe_kpi * 100,
      vessel_id: item.vessel_id,
      vessel_name: vData.find((e) => e.id === item.vessel_id)?.name ?? "",
      fleet_id: data.fleet_id,
    }));

    return { chartData, minBench, maxBench };
  }, [data, lowerData, upperData, vData]);

  return { data: chartData, isFetching: isFetching || loading, isError, minBench, maxBench };
}

export { usePPEFleetData, usePPESingleFleetData };
