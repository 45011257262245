import styled from "styled-components";
import { color, rem } from "../../../config/variable.styles";

export const AllEventTooltipStyle = styled.div`
  width: ${rem(360)};
  .head {
    padding: ${rem(14)} ${rem(20)};
    background: ${color.SapphireDark};
    border-radius: 0 ${rem(4)} 0 0;
    [data-popper-placement="top-end"] & {
      border-radius: ${rem(4)} 0 0 0;
    }
    &__subtitle {
      font-size: ${rem(14)};
      font-weight: 400;
      line-height: ${rem(17)};
      color: ${color.DarkBlack};
      margin: 0 0 ${rem(3)};
    }
    &__title {
      font-size: ${rem(16)};
      font-weight: 800;
      line-height: ${rem(20)};
      color: ${color.White};
      margin: 0 0 ${rem(1)};
    }
    &__info {
      font-size: ${rem(14)};
      font-weight: 400;
      line-height: ${rem(17)};
      color: ${color.White};
      margin: 0 0 ${rem(2)};
    }
  }
  .body {
    padding: ${rem(12)} ${rem(20)};
    .event-icon {
      display: flex;
      justify-content: space-between;
      margin: 0 -15px;
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--disable {
          user-select: none;
          pointer-events: none;
          opacity: 0.2;
        }
        /* .icon {
          display: flex;
          flex: 0 0 ${rem(32)};
          width: ${rem(32)};
          font-size: ${rem(32)};
        } */
        p {
          flex: 1 1 auto;
          font-size: ${rem(14)};
          font-weight: 400;
          line-height: 1.57;
          color: ${color.DarkBlack};
          margin: ${rem(0)};
        }
        .count {
          font-size: ${rem(14)};
          font-weight: 800;
          padding-left: ${rem(7)};
          line-height: 1.57;
          color: ${color.DarkBlack};
        }
      }
    }
  }
`;

export const TimelineFieldContainer = styled.div<{ maxwidth: number }>`
  flex: 0 1 ${(props) => rem(props.maxwidth)};
  max-width: ${(props) => rem(props.maxwidth)};
  padding: 0 15px;
  &:last-child {
    border-right: none;
  }
`;
