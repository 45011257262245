import { useEffect, useMemo } from "react";
import ChartCard from "components/ReportChartCard/ChartCard";
import { XYChart, XYChartData } from "components/Charts";
import { formatDate } from "utils";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getCoverageOverview } from "services/redux/reducers/Reports/Reports.slice";
import InfoSummaryBar from "components/InfoSummaryBar/InfoSummaryBar";
import { useReportsContext } from "../utils";
import Skeleton from "components/Loaders/Skeleton";
import { setSelectedFleetCoverage } from "services/redux/reducers/Reports/ReportsSelector.slice";
import useFleets from "hooks/useFleets";

const CoverageOverview = () => {
  const { colors } = useReportsContext();
  const dispatch = useAppDispatch();
  const chart = useAppSelector((state) => state.reports);
  const {
    fleet,
    compare_to,
    dateRange: { start, end },
  } = useAppSelector((state) => state.reportsFilter);
  const refresh = useAppSelector((state) => state.refreshTimer.manualRefresh);
  const { loading } = useFleets();
  const isLoading = chart.status === "loading" || loading;
  const error = chart.status === "error";

  const { chartData, benchmarkRange, maxValues } = useMemo(() => {
    if (!chart.data?.data_points.length) return { chartData: [], benchmarkRange: [] };

    const transformedData: XYChartData[] = [];

    const dates = chart.data.data_points.map((e) => e.date);
    const fleets = chart.data.data_points.map((e) => e.fleets);
    const benchmarkRange = chart.data.data_points.map(({ fleets, ...rest }) => ({ ...rest }));
    const selectedFleetIDs = [...new Set(fleets.map((e) => e.map((i) => i.fleet_id)).flat())];

    selectedFleetIDs.forEach((fleet) => {
      const combined = dates
        .map((date, i) => {
          const info = fleets[i].find((e) => e.fleet_id === fleet);
          if (info?.coverage != null) {
            return { date, fleet_id: fleet, value: info?.coverage ?? 0, color: colors[fleet] };
          }
          return null;
        })
        .filter((e) => !!e) as XYChartData;
      transformedData.push(combined);
    });

    const maxValues = transformedData.map((i) => i[i.length - 1]);
    return { chartData: transformedData, benchmarkRange, maxValues };
  }, [chart.data?.data_points, colors]);

  useEffect(() => {
    if (!fleet?.id) return;

    dispatch(
      getCoverageOverview({
        start_dttm: formatDate(start, "yyyy-MM-dd"),
        end_dttm: formatDate(end, "yyyy-MM-dd"),
        buckets: 15,
        fleets: [fleet.id, ...compare_to],
      })
    );
  }, [dispatch, start, end, refresh, fleet?.id, compare_to]);

  const range = benchmarkRange?.[benchmarkRange?.length - 1];

  return (
    <ChartCard
      bottomRadiusNone
      loading={isLoading}
      subTitle="Coverage Overview"
      title="Fleet Watch Level Performance"
      tooltipContent={
        <div className="headingPopoverContent">
          <ul>
            <li>
              {/* eslint-disable-next-line max-len */}
              For each point in time, a graph for each fleet shows the percentage of Underway time during the preceding 30 days in which a person was
              detected <br />
              in the wheelhouse, averaged for all vessels in that fleet. The industry benchmark is mark as a reference.
            </li>
          </ul>
        </div>
      }
    >
      <div className="reportsHeadingSummary">
        <Skeleton loading={isLoading} height={60} width={400}>
          <div className="infoSummaryWidget">
            <InfoSummaryBar labels={maxValues} benchmarkRange={{ min: range?.industry_benchmark_lower, max: range?.industry_benchmark_upper }} />
          </div>
        </Skeleton>
      </div>
      <XYChart
        start={start}
        end={end}
        data={chartData}
        loading={isLoading}
        error={error}
        containerID="chartdiv2"
        benchmarkRange={benchmarkRange}
        onSelect={(data) => dispatch(setSelectedFleetCoverage(data))}
      />
    </ChartCard>
  );
};

export { CoverageOverview };
