/* eslint-disable max-lines */
import { color, rem, rgba, Transition, xxl } from "config/variable.styles";
import styled from "styled-components";
export const ReportsWrapper = styled.div`
  position: relative;
  border-top: ${rem(1)} solid ${color.graywhiteTwo};
`;
export const RecordsContainer = styled.div`
  display: block;
  position: relative;
  z-index: 11;
`;
export const TableFilter = styled.div`
  background-color: ${color.White};
  position: sticky;
  top: ${rem(38)};
  left: 0;
  right: 0;
  width: 100%;
  z-index: 20;
  transition: ${Transition};
  &.isSticky {
    box-shadow: 0 ${rem(4)} ${rem(4)} 0 ${rgba(color.Black, 0.2)};
  }
`;
export const EngineRoomVesselFilter = styled.div`
  .isSticky {
    box-shadow: inherit;
  }
`;
export const ReportHeaderContainer = styled.div`
  background-color: ${color.White};
  position: sticky;
  top: ${rem(-1)};
  left: 0;
  right: 0;
  width: 100%;
  z-index: 20;
  .sectionHeaderRow {
    border-bottom-color: ${color.White};
  }
`;
export const ReportCharts = styled.div`
  display: block;
  position: relative;
  background-color: ${color.White};
  padding: ${rem(0)};
  height: calc(100vh - ${rem(153)});
  overflow: auto;
  &.fleetPageScroll,
  &#engine-vessel-scroll,
  &#engine-fleet-scroll,
  &#bridge-report,
  &#marpol-fleet-scroll {
    height: calc(100vh - ${rem(205)});
  }
  .chart-custom-legend {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 0 ${rem(20)} 0;
    padding: 0;
    &.mb0pr20 {
      margin-top: ${rem(10)};
      margin-bottom: 0;
      padding-right: ${rem(20)};
    }
    li {
      margin-left: ${rem(20)};
      font-size: ${rem(12)};
      color: ${color.DarkBlack};
      font-weight: 600;
      list-style: none;
      display: flex;
      align-items: center;
      line-height: ${rem(12)};
      .color-box {
        display: inline-block;
        width: ${rem(12)};
        height: ${rem(12)};
        background-color: ${color.coral};
        margin-left: ${rem(10)};
        &.bridge {
          margin: 0 ${rem(10)} 0 0;
        }
        &.bgRed {
          background-color: ${color.bgRed};
        }
        &.bgBrightTurquoise {
          background-color: ${color.bgBrightTurquoise};
        }
        &.bgHeliotrope {
          background-color: ${color.bgHeliotrope};
        }
        &.bgYellow {
          background-color: ${color.bgYellow};
        }
        &.bgGreen {
          background-color: ${color.aquaMarine};
        }
        &.bgPurple {
          background-color: ${color.lighterPurple};
        }
        &.bgLightBlue {
          background-color: ${color.perrywinkle};
        }
        &.bgGrey {
          background-color: ${color.lightGray};
        }
        &.bgDarkBlue {
          background-color: ${color.bgDarkBlue};
        }
      }
    }
  }
  .card-white-bg {
    padding-bottom: ${rem(15)};
  }
  .item-card {
    border-bottom: ${rem(10)} solid ${color.duckEggBlue} !important;
    border-left: ${rem(10)} solid ${color.duckEggBlue} !important;
    border-right: ${rem(10)} solid ${color.duckEggBlue} !important;
    &.border-none {
      border: none !important;
    }
    &.border-bottom-none {
      border-bottom: none !important;
    }
    &.border-right-none {
      border-right: none !important;
    }
    &.border-left-none {
      border-left: none !important;
    }
    &.border-bottom {
      border-bottom: ${rem(10)} solid ${color.duckEggBlue} !important;
    }
    &.border-right-5 {
      border-right-width: ${rem(5)} !important;
    }
    &.border-left-5 {
      border-left-width: ${rem(5)} !important;
    }
    &.border-top {
      border-top: ${rem(10)} solid ${color.duckEggBlue} !important;
    }
    .MuiGrid-container {
      .item-card {
        &:nth-child(1) {
          border-top: ${rem(10)} solid ${color.duckEggBlue};
          border-left: none !important;
          border-right: none !important;
        }
        &:nth-child(2) {
          border-top: ${rem(10)} solid ${color.duckEggBlue};
          border-right: none !important;
        }
        &:nth-child(3) {
          border-top: ${rem(10)} solid ${color.duckEggBlue};
          border-right: none !important;
        }
      }
    }
  }
  .engineRoomFleet {
    .item-card {
      border-top: ${rem(1)} solid ${color.duckEggBlue} !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: none !important;
      &.bt-bold {
        border-top: ${rem(10)} solid ${color.duckEggBlue} !important;
      }
      &.bb-bold {
        border-bottom: ${rem(10)} solid ${color.duckEggBlue} !important;
      }
      &.border-right {
        border-right: ${rem(1)} solid ${color.duckEggBlue} !important;
      }
      &.br-bold {
        border-right: ${rem(10)} solid ${color.duckEggBlue} !important;
      }
      &.border-left-red {
        border-left: ${rem(5)} solid ${color.coral} !important;
      }
      &.border-left-yellow {
        border-left: ${rem(5)} solid ${color.paleOrange} !important;
      }
      &.bt-none {
        border-top: none !important;
      }
      .engineRoomFleetSub {
        margin: 0 ${rem(-10)};
        margin-top: ${rem(28)};
        .item-card-sub {
          padding: 0 ${rem(10)};
          padding-bottom: ${rem(24)};
          .engineControlHeatMap {
            margin-bottom: ${rem(8)};
            font-size: ${rem(16)};
            font-weight: 600;
            line-height: normal;
            color: ${color.DarkBlack};
          }
        }
      }
    }
    &.engineRoomFleetInner {
      border-top: ${rem(10)} solid ${color.duckEggBlue} !important;
    }
  }
  .custom-left-chart-width {
    width: 55%;
    max-width: 55%;
    flex-basis: 55%;
    ${xxl(`
        width:60%;
        max-width:60%;
        flex-basis: 60%;
    `)}
  }
  .custom-right-chart-width {
    width: 45%;
    max-width: 45%;
    flex-basis: 45%;
    ${xxl(`
        width:40%;
        max-width:40%;
        flex-basis: 40%;
    `)}
  }
  .bridgeItemCard {
    border-bottom: ${rem(10)} solid ${color.duckEggBlue} !important;
    border-left: ${rem(10)} solid ${color.duckEggBlue} !important;
    border-right: ${rem(10)} solid ${color.duckEggBlue} !important;
    &.bridgeItemCardCol {
      border-right: none !important;
    }
    &.bridgeItemCardList {
      border-right: ${rem(10)} solid ${color.duckEggBlue} !important;
    }
    &.border-top {
      border-top: ${rem(10)} solid ${color.duckEggBlue} !important;
    }
  }
  .bridgeItemPadding {
    .card-white-bg {
      padding-bottom: 0;
    }
  }
  .marpolActivitiesTitle {
    font-size: ${rem(20)};
    font-weight: 700;
    color: ${color.DarkBlack};
    margin: ${rem(20)} ${rem(15)} ${rem(15)};
  }
  .marpolSummaryTitleRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    padding: ${rem(15)};
    span.borderLeft {
      position: absolute;
      height: ${rem(35)};
      width: ${rem(6)};
      top: ${rem(16)};
      left: 0;
    }
    .marpolSummaryTitle {
      flex: 1 1 auto;
      margin-left: ${rem(15)};
      max-width: 80%;
      h2 {
        font-size: ${rem(16)};
        font-weight: 900;
        color: ${color.DarkBlack};
        margin: 0;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        div {
          display: flex;
          align-items: center;
          margin-left: ${rem(10)};
          line-height: 0;
          cursor: pointer;
        }
      }
      h4 {
        font-size: ${rem(14.4)};
        font-weight: 400;
        color: ${color.DarkBlack};
        margin: 0;
        text-transform: capitalize;
      }
    }
    .marpolSummaryTitleNumber {
      width: 15%;
      flex: 0 0 15%;
      max-width: 15%;
      h3 {
        font-size: ${rem(16)};
        font-weight: 900;
        color: ${color.DarkBlack};
        margin: 0;
        text-align: right;
      }
      p {
        font-size: ${rem(14)};
        font-weight: 400;
        color: ${color.DarkBlack};
        margin: 0;
        text-align: right;
      }
    }
  }
  .marpolSummaryChart {
    width: 100%;
    text-align: center;
    height: ${rem(300)};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }
`;
export const ChartCardContentList = styled.div`
  position: sticky;
  top: ${rem(92)};
  left: 0;
  right: 0;
  width: 100%;
  border-top: ${rem(1)} solid ${color.graywhiteTwo};
  background: ${color.White};
  padding: ${rem(18)} ${rem(20)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 19;
  transition: ${Transition};
  &.isSticky {
    box-shadow: 0 ${rem(4)} ${rem(4)} 0 ${rgba(color.Black, 0.2)};
  }
  p {
    margin: 0;
    font-size: ${rem(12)};
    font-weight: 600;
    line-height: normal;
    color: ${color.DarkBlack};
  }
  .customLegendRow {
    display: flex;
    align-items: center;
    .chartCustomLegend {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 0 ${rem(0)};
      padding: 0;
      &.mb0pr20 {
        margin-bottom: 0;
        padding-right: ${rem(20)};
      }
      li {
        margin-left: ${rem(20)};
        font-size: ${rem(12)};
        color: ${color.DarkBlack};
        font-weight: 600;
        list-style: none;
        display: flex;
        align-items: center;
        .color-box {
          display: inline-block;
          width: ${rem(12)};
          height: ${rem(12)};
          background-color: ${color.coral};
          margin-right: ${rem(10)};
          &.bgRed {
            background-color: ${color.bgRed};
          }
          &.bgBrightTurquoise {
            background-color: ${color.bgBrightTurquoise};
          }
          &.bgHeliotrope {
            background-color: ${color.bgHeliotrope};
          }
          &.bgYellow {
            background-color: ${color.bgYellow};
          }
          &.bgGreen {
            background-color: ${color.aquaMarine};
          }
          &.bgPurple {
            background-color: ${color.lighterPurple};
          }
          &.bgLightBlue {
            background-color: ${color.perrywinkle};
          }
          &.bgDarkBlue {
            background-color: ${color.bgDarkBlue};
          }
          &.bgGrey {
            background-color: ${color.lightGray};
          }
          &.bgGraywhiteTwo {
            background-color: ${color.graywhiteTwo};
          }
          &.bgFireEngineRed {
            background-color: ${color.fireEngineRedColor};
          }
          &.bgPaleOrange {
            background-color: ${color.paleOrange};
          }
          &.bgAquaMarine {
            background-color: ${color.aquaMarine};
          }
        }
      }
      &.chartCustomLegendRight {
        border-left: ${rem(1)} solid ${color.graywhiteTwo} !important;
        margin-left: ${rem(48)};
        padding-left: ${rem(31)};
        &.noBorder {
          border-left: none !important;
          margin-left: ${rem(0)};
          padding-left: ${rem(0)};
        }
      }
    }
  }
`;

export const PPEReportChartWrap = styled.div`
  margin: ${rem(10)};
  background-color: ${color.White};
  height: calc(100vh - ${rem(165)});
  overflow: auto;
  .PPEReportChartHeading {
    font-size: ${rem(16)};
    font-weight: 900;
    color: ${color.DarkBlack};
    margin: 0;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: ${rem(10)};
  }
`;

export const PPEReportChart = styled.div`
  padding: ${rem(18)};
  padding-bottom: ${rem(5)};
`;

export const MarpolVesselTimeLine = styled.div`
  background-color: ${color.White};
  /* border-top: ${rem(1)} solid ${color.graywhiteTwo}; */
  padding: ${rem(20)} ${rem(20)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  .marpolVesselTitle {
    flex: 1 1 auto;
    h2 {
      margin: 0 0 ${rem(5)};
      font-size: ${rem(24)};
      font-weight: 700;
      color: ${color.oceanBlue};
    }
    span {
      display: block;
      font-size: ${rem(18)};
      font-weight: 700;
      color: ${color.DarkBlack};
    }
  }
  .marpolVesselCounterRow {
    width: 40%;
    max-width: 40%;
    flex: 0 0 40%;
    margin-left: ${rem(20)};
  }
`;

export const DashboardExploreButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;
