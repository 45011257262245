import { useAppSelector } from "hooks";
import { convertToTimestamp, formatDate } from "utils";
import { useBridgeNavigationQuery, useReportsTimelineQuery } from "queries";
import Skeleton from "components/Loaders/Skeleton";
import CompartmentBridgeWtatch from "components/Charts/CompartmentBridgeWatch";

const NON_COMPLIANT_WATCH_LEVEL = "Non-compliant watch level";
const BRIDGE_LIGHTING = "Bridge Lighting at Night while Underway";
const UNATTENDED_BRIDGE_UNDERWAY = "Unattended Bridge while Underway";
const UNATTENDED_BRIDGE_ANCHOR = "Unattended Bridge while At Anchor";

const BridgeWatchReport = () => {
  const isFleetsLoading = useAppSelector((state) => state.fleetInfo.status === "loading");
  const { vessel, dateRange } = useAppSelector((state) => state.reportsFilterOld);
  const vesselId = vessel?.id;

  const navOptions = {
    vessel,
    start: formatDate(dateRange.start),
    end: formatDate(dateRange.end, "yyyy-MM-dd'T'23:59:59"),
    severity: ["Attention"],
  };

  const { start, end } = navOptions;

  const { data, isError: navError } = useBridgeNavigationQuery(navOptions);
  const {
    data: timelines = {},
    isFetching,
    isError,
  } = useReportsTimelineQuery({
    range: { start, end },
    filters: {
      titles: [NON_COMPLIANT_WATCH_LEVEL, BRIDGE_LIGHTING, UNATTENDED_BRIDGE_UNDERWAY, UNATTENDED_BRIDGE_ANCHOR],
      vesselIds: !!vessel?.id ? [vessel?.id] : [],
      startTimestamp: convertToTimestamp(start),
      endTimestamp: convertToTimestamp(end),
    },
  });

  const mock = (title: string) => [
    {
      amc_fromDate: new Date(start),
      amc_toDate: new Date(end),
      type: title,
      color: "#EFEFEF",
      data: { Attention: 0, Alert: 0, Routine: 0 },
    },
  ];

  const bridge = timelines[BRIDGE_LIGHTING] ?? mock(BRIDGE_LIGHTING);
  const nonCompliant = timelines[NON_COMPLIANT_WATCH_LEVEL] ?? mock(NON_COMPLIANT_WATCH_LEVEL);
  const unattendedAnchor = timelines[UNATTENDED_BRIDGE_ANCHOR] ?? mock(UNATTENDED_BRIDGE_ANCHOR);
  const unattendedBridge = timelines[UNATTENDED_BRIDGE_UNDERWAY] ?? mock(UNATTENDED_BRIDGE_UNDERWAY);

  return (
    <div style={{ position: "relative", top: "-10px", height: "160px" }}>
      <Skeleton loading={isFetching || isFleetsLoading} height={160} width="100%">
        <>
          <CompartmentBridgeWtatch
            width={"20vw"}
            containerID="unattendedBridge"
            data={unattendedBridge}
            error={isError}
            start={start}
            end={end}
            vessel={vesselId}
            enableTooltip
          />
          <CompartmentBridgeWtatch
            width={"20vw"}
            containerID="unattendedAnchor"
            data={unattendedAnchor}
            error={isError}
            start={start}
            end={end}
            vessel={vesselId}
            enableTooltip
          />
          <CompartmentBridgeWtatch
            width={"20vw"}
            containerID="nonCompliant"
            data={nonCompliant}
            error={isError}
            start={start}
            end={end}
            vessel={vesselId}
            enableTooltip
          />
          <CompartmentBridgeWtatch
            enableTooltip
            width={"20vw"}
            containerID="bridge"
            data={bridge}
            error={isError}
            start={start}
            end={end}
            vessel={vesselId}
          />
          {data ? (
            <CompartmentBridgeWtatch
              enableTooltip
              width={"20vw"}
              containerID="navTimeline"
              data={data}
              error={navError}
              label="Navigation Status"
              dateAxisvisible
              start={start}
              end={end}
              vessel={vesselId}
            />
          ) : null}
        </>
      </Skeleton>
    </div>
  );
};

export { BridgeWatchReport };
