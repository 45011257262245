import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { color } from "config/variable.styles";
import useFleets from "hooks/useFleets";

interface ReportsContextValue {
  colors: Record<string, string>;
  fleetNames: Record<string, string>;
  fleetInfo: Record<string, string>;
}

const ReportsContext = createContext<ReportsContextValue | null>(null);

const colorValues = [
  color.bgBrightTurquoise,
  color.bgHeliotrope,
  color.bgYellow,
  color.bgOrange,
  color.bgGray,
  color.bgBlue,
  "#FEAF62",
  "#11F3A0",
  "#FEAFF9",
  "#BEC9ED",
  "#ECF200",
  "#8D77FF",
];

const ReportsProvider = (props: PropsWithChildren<{}>) => {
  const { data } = useFleets((fleets) => {
    const map: Record<string, string> = {};
    fleets?.fleets.forEach((fleet) => (map[fleet.id] = fleet.name));
    return map;
  });
  const { data: fleets } = useFleets();

  const value = useMemo(() => {
    const colorsInfo: ReportsContextValue["colors"] = {};
    const alertNamesInfo: ReportsContextValue["fleetInfo"] = {};
    fleets?.fleets.forEach((fleet, i) => {
      colorsInfo[fleet.id] = colorValues[i % colorValues.length];
      alertNamesInfo[`${i}`] = data[fleet.id];
    });
    return { colors: colorsInfo, fleetNames: data, fleetInfo: alertNamesInfo };
  }, [fleets, data]);

  return <ReportsContext.Provider value={value} {...props} />;
};

const useReportsContext = () => {
  const context = useContext(ReportsContext);

  if (!context) throw new Error("Invalid hook call");

  return context;
};

export { ReportsProvider, useReportsContext, colorValues };
