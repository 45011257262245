import { useLayoutEffect, useRef, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { VirtualElement } from "@popperjs/core";
import { ChartContainer } from "./Charts.styles";
import { XYChartData, IStackChartProps } from "./Charts.types";
import Skeleton from "components/Loaders/Skeleton";
import PopperPopover from "components/PopperPopover";
import GraphTooltip from "components/Reports/GraphTooltip";
import { useReportsContext } from "components/Reports";
import { Error } from "./Error";

am4core.options.autoDispose = true;

const strokeColor = "#d6dce5";
const StackChart = (props: IStackChartProps) => {
  const {
    data,
    containerID,
    loading,
    error,
    showStats = false,
    onSelect,
    countData,
    isOldAlertSummary = false,
    compareTo,
    isVesselReport = false,
  } = props;
  const { fleetNames } = useReportsContext();
  const onSelectRef = useRef(onSelect);
  const noData = compareTo ? data?.[0]?.every((e) => !e.value) && data?.[1]?.every((e) => !e.value) : data?.[0]?.every((e) => !e.value);
  const [tooltip, setTooltip] = useState<{ data: XYChartData[number]; referenceElement: Element | VirtualElement } | null>(null);

  useLayoutEffect(() => {
    if (!data || !data.length || noData) return;

    const chart = am4core.create(containerID, am4charts.XYChart);
    chart.paddingRight = 35;
    chart.zoomOutButton.disabled = true;

    //isVesselReport is used to detect if it's vessel report or not
    if (isVesselReport) {
      chart.height = 200;
    }

    const xAxes = chart.xAxes.push(new am4charts.ValueAxis());
    xAxes.renderer.minGridDistance = 50;
    xAxes.renderer.grid.template.location = 0.5;
    xAxes.startLocation = 0.5;
    xAxes.endLocation = 0.5;
    xAxes.renderer.labels.template.fontSize = 12;
    xAxes.renderer.grid.template.visible = false;
    xAxes.renderer.line.strokeOpacity = 1;
    xAxes.renderer.line.stroke = am4core.color(strokeColor);
    xAxes.renderer.ticks.template.disabled = false;
    xAxes.renderer.ticks.template.strokeOpacity = 1;
    xAxes.renderer.ticks.template.above = true;
    xAxes.renderer.ticks.template.length = 12;
    xAxes.renderer.ticks.template.dy = -12;
    xAxes.renderer.ticks.template.stroke = am4core.color(strokeColor);
    xAxes.min = 0;

    const yAxes = chart.yAxes.push(new am4charts.ValueAxis());
    yAxes.renderer.grid.template.visible = false;
    yAxes.renderer.line.strokeOpacity = 1;
    yAxes.renderer.line.stroke = am4core.color(strokeColor);
    yAxes.renderer.labels.template.disabled = true;
    yAxes.min = 0;
    yAxes.max = data.length + 1;

    function createSeries(color: string, data: any[], isBenchmark: boolean) {
      const series1 = chart.series.push(new am4charts.LineSeries());
      series1.dataFields.valueX = "value";
      series1.dataFields.valueY = "date";
      series1.strokeWidth = 3;
      series1.tensionX = 0.8;
      series1.data = data;
      series1.stroke = am4core.color(color);
      series1.name = color;
      series1.showOnInit = false;
      series1.defaultState.transitionDuration = 0;
      series1.hiddenState.transitionDuration = 0;
      series1.interpolationDuration = 0;

      if (!showStats) {
        const range = yAxes.axisRanges.create();
        range.label.fontSize = 10;

        const { value, text } = isBenchmark
          ? { value: 1, text: compareTo ? compareTo : "Benchmark" }
          : { value: data?.[0]?.date, text: data?.[0]?.fleet };
        range.value = value;
        range.label.text = text;
        range.label.fontSize = 11;
      }

      const bullet = series1.bullets.push(new am4charts.CircleBullet());
      bullet.height = 24;
      bullet.width = 24;

      bullet.propertyFields.fill = "color";
      bullet.propertyFields.fillOpacity = "opacity";
      bullet.fillOpacity = 0;
      bullet.strokeWidth = 0;
      bullet.circle.radius = 6;
      bullet.events.on("over", (e: any) => {
        e.event.stopPropagation();
        setTooltip({ referenceElement: e.event.target, data: e.target.dataItem?.dataContext });
      });
      bullet.events.on("out", () => setTooltip(null));

      if (!isBenchmark && onSelectRef.current) {
        bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        bullet.events.on("hit", (e: any) => {
          e.event.stopPropagation();
          const dataItem = e.target.dataItem?.dataContext;
          if (dataItem) onSelectRef.current?.(e.event.target, dataItem, chart);
        });
      }
    }

    if (data?.[0]?.[0]?.label) {
      const label = chart.createChild(am4core.Label);
      label.text = data[0][0].label;
      label.fontSize = 10;
      label.align = "center";
      label.isMeasured = false;
      label.x = data[0][0].label === "Rate of occurrences/Mo" ? 70 : -4;
      label.y = isVesselReport ? 170 : 225;
    }

    data.forEach((item: any, i: number) => {
      if (!item.length) return;
      createSeries(item[0].color, item, i === 0);
    });

    return () => {
      chart.dispose();
    };
  }, [data, containerID, showStats, noData, compareTo, isVesselReport]);

  if ((error || noData || isOldAlertSummary) && !loading) {
    return (
      <ChartContainer>
        <Error variant={error ? "error" : "nodata"} height={isVesselReport ? 230 : 280} size="xs" text="Alerts" />
      </ChartContainer>
    );
  }

  return (
    <ChartContainer>
      {showStats && (
        <div className="chart-info-row">
          <div className="chart-info-item text-right">
            <Skeleton loading={loading} animation="wave" height={30} width="68px">
              <h4>{countData ?? 0}</h4>
            </Skeleton>
            <Skeleton loading={loading} animation="wave" height={20} width="68px">
              <p className="chart-info-text">Occurrences</p>
            </Skeleton>
          </div>
        </div>
      )}
      {!!tooltip && (
        <PopperPopover
          backgroundColor="transparent"
          className="reports-popover"
          referenceElement={tooltip.referenceElement}
          placement="top-start"
          noborder
        >
          <GraphTooltip
            values={[
              ...(fleetNames[tooltip.data.fleet_id] ? [{ title: "Fleet", value: fleetNames[tooltip.data.fleet_id] }] : []),
              { title: "KPI", value: parseFloat(`${tooltip.data.value}`)?.toFixed(1) },
            ]}
          />
        </PopperPopover>
      )}

      <Skeleton loading={loading} animation="wave" height={isVesselReport ? 230 : 290} width="100%">
        <div id={containerID} style={{ height: isVesselReport ? "220px" : "260px", width: "100%" }} />
      </Skeleton>
    </ChartContainer>
  );
};

export default StackChart;
