import { color, rem } from "config/variable.styles";
import styled from "styled-components";

export const ChartCardStyles = styled.div<{ height?: number | string }>`
  position: relative;
  padding: 0;
  border-radius: 6px;
  height: ${({ height }) => (height ? height : "100%")};
  .top-label {
    position: absolute;
    top: ${rem(14)};
    right: ${rem(10)};
  }
  &.topRadiusNone {
    border-radius: 0 0 6px 6px;
    height: ${({ height }) => (height ? height : "100%")};
  }
  &.bottomRadiusNone {
    border-radius: 6px 6px 0 0;
  }
  &.rightGrayBorder {
    .ChartCardContent {
      /* border-right: ${rem(1)} solid ${color.duckEggBlue}; */
      border-right: none;
      .item-card {
        border: none !important;
      }
    }
  }
  .item-card {
    border: none;
  }
  .heading {
    margin: 0 0 ${rem(10)};
    padding: ${rem(15)} ${rem(20)} 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    &.engineRoomCompartment {
      h2 {
        text-transform: none;
      }
    }
    &.genericTitle {
      h2 {
        text-transform: none;
      }
    }
    h2 {
      font-size: ${rem(16)};
      font-weight: 900;
      color: ${color.DarkBlack};
      margin: 0;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      white-space: nowrap;
      &.blue {
        color: ${color.navyBlue};
      }
      div {
        display: flex;
        align-items: center;
        margin-left: 10px;
        line-height: 0;
      }
    }
    h4 {
      font-size: ${rem(14.4)};
      font-weight: 400;
      color: ${color.DarkBlack};
      margin: 0;
      text-transform: capitalize;
    }
    .headingTitle {
      display: flex;
      flex-wrap: wrap;
      &.orderingHeading {
        h4 {
          width: 100%;
          order: 2;
        }
        h2 {
          width: 100%;
          order: 1;
        }
      }
    }
    &.report-chart-card {
      h2 {
        text-transform: inherit;
      }
    }
  }
  .reportsHeadingSummary {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-right: ${rem(0)};
    margin-top: ${rem(-50)};
    justify-content: flex-end;
    .infoSummaryWidget {
      width: 90%;
    }
    .menuRightBtn {
      cursor: pointer;
      margin-left: ${rem(20)};
    }
  }
  .engineRoomRightRow {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .menuRightBtn {
      cursor: pointer;
      margin-left: ${rem(20)};
    }
  }
  .ChartCardContent {
    padding: 0 ${rem(20)} ${rem(10)};
    img {
      display: block;
      height: ${rem(220)};
      max-width: 100%;
    }
    .leaflet-touch {
      height: ${({ height }) => (height ? rem(height) : rem(456))};
      z-index: 10;
    }
  }
  .AlertChartContent {
    display: flex;
  }
`;
